export const PublicProjects = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1048 1000"
      width="63"
      height="61"
      {...props}
    >
      <defs>
        <style>
          {
            ".public-projects-cls-3{stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:28px;fill:none}"
          }
        </style>
      </defs>
      <path
        style={{
          fill: "#0297a7",
        }}
        d="M85 779.35h900.25V989.5H85z"
      />
      <path
        d="M513.89 557.18h0c-62.68 0-114-76.84-114-139.91v-85.21c0-63.06 51.28-114.66 114-114.66h0c62.68 0 114 51.6 114 114.66v85.21c-.04 63.07-51.32 139.91-114 139.91Z"
        style={{
          stroke: "#000",
          strokeLinecap: "round",
          strokeMiterlimit: 10,
          strokeWidth: 28,
          fill: "#fec111",
        }}
      />
      <circle cx={460.05} cy={390.95} r={17.25} />
      <circle cx={567.73} cy={390.95} r={17.25} />
      <circle cx={513.89} cy={493.93} r={17.25} />
      <path
        className="public-projects-cls-3"
        d="M31.75 779.35h953.5V989.5H31.75zM467.72 679.55h0a82.09 82.09 0 0 1 30.66-5.9l62.06.09a82 82 0 0 1 30.85 6.06l244.45 99.55M218.9 779.35l200.79-80.53M513.89 557.18h0c-62.68 0-114-76.84-114-139.91v-85.21c0-63.06 51.28-114.66 114-114.66h0c62.68 0 114 51.6 114 114.66v85.21c-.04 63.07-51.32 139.91-114 139.91Z"
      />
      <circle
        className="public-projects-cls-3"
        cx={99.71}
        cy={846.27}
        r={29.27}
      />
      <circle
        className="public-projects-cls-3"
        cx={916.25}
        cy={846.27}
        r={30.32}
      />
      <path
        className="public-projects-cls-3"
        d="M442.79 243.65V13M584.98 241.44V13M543.68 553s-2.09 34.5 20.91 69 26 103.5-34.66 122.32-98.27-20.91-104.55-32.41c-9.72-17.82 3.3-53.32-22.84-78.41s4.15-39 13.59-39.73c13.59-1 40.78 7.32 42.87 42.86s3 55.05 25.09 55.41c29.31.49 28.23-26.13 25.09-40.77s-13.59-36.59-24.05-55.41-8-40.39-8-40.39"
      />
    </svg>
  );
};
