import { useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import Image from 'next/image';
import fallbackImage from "../assets/blog-image-placeholder.png";
import Storyblok from 'utils/storyblok';
import { isDev } from 'constants/env';
import { useRouter } from 'next/router';
import { transformImage } from 'utils/image.helper';
import { getTranslatedSlug } from 'utils/router.helper';
import Link from 'next/link';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

function CategoryDropdown({ blok }) {
  const router = useRouter();
  const lang = router.locale
  const [story, setStory] = useState<any>({
    stories: []
  });

  useEffect(() => {
    getStory();
  }, [lang]);

  const getStory = async () => {
    const sbParam = {
      version: isDev() ? "draft" : "published",
      language: lang,
      starts_with: `blogs`,
      sort_by: "first_published_at:desc",
      per_page: 2,

    }
    try{
      const blogOverviewData = await Storyblok.get(
        "cdn/stories",
        sbParam
      )
      setStory(blogOverviewData.data)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            className={`${open ? 'text-gray-900' : 'text-gray-500'
              } group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan`}
          >
            <span>{blok.buttonText}</span>
            <ChevronDownIcon
              className={`${open ? 'text-gray-600' : 'text-gray-400'
                } ml-2 h-5 w-5 group-hover:text-gray-500`}
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition
            show={open}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="absolute z-10 transform shadow-lg rounded-lg ring-1 ring-black ring-opacity-5 overflow-scroll h-96 md:h-auto inset-x-0 max-w-sm md:max-w-none mt-3 mx-2">
              <div className="absolute inset-0 flex">
                <div className="bg-white w-1/2" />
                <div className="bg-gray-50 w-1/2" />
              </div>
              <div className="relative max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2">
                <nav className="grid gap-y-10 px-4 py-8 bg-white sm:grid-cols-2 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12">
                  <div>
                    <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">{blok.title1}</h3>
                    <ul className="mt-5 space-y-6">
                      {blok.items1.map((item) => (
                        <li key={item.text} className="flow-root">
                          <Link href={item.destination.url} >
                            <a
                              className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                            >
                              <img className="flex-shrink-0 h-6 w-6 text-gray-400" src={item.image} alt="" width={24} height={24} />
                              <span className="ml-4">{item.text}</span>
                            </a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">{blok.title2}</h3>
                    <ul className="mt-5 space-y-6">
                      {blok.items2.map((item) => (
                        <li key={item.text} className="flow-root">
                          <Link href={item.destination.url} >
                          <a

                            className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                          >
                            <img className="flex-shrink-0 h-6 w-6 text-gray-400" src={item.image} alt="" width={24} height={24} />
                            <span className="ml-4">{item.text}</span>
                          </a>
                          
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </nav>
                <div className="bg-gray-50 px-4 py-8 sm:py-12 sm:px-6 lg:px-8 xl:pl-12">
                  <div>
                    <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">{blok.blogText}</h3>
                    <ul className="mt-6 space-y-6">
                      {story.stories.slice(0, 2).map((post) => (
                        <li key={post.id} className="flow-root">
                          <Link href={post.full_slug} >
                          <a  className="-m-3 p-3 flex rounded-lg hover:bg-gray-100">
                            <div className="hidden sm:block flex-shrink-0">
                              <img
                                className="w-32 h-20 object-cover rounded-md"
                                src={post.content?.coverimage?.filename ? transformImage(post.content?.coverimage?.filename, '300x0/filters:quality(60)') : fallbackImage.src}
                                alt=""
                                width={160}
                                height={100}
                              />
                            </div>
                            <div className="w-0 flex-1 sm:ml-8">
                              <h4 className="text-base font-medium text-gray-900 truncate">{post.content.name}</h4>
                              <p className="mt-1 text-sm text-gray-500">{post.content.intro}</p>
                            </div>
                          </a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-6 text-sm font-medium">
                    <Link href={blok.blogLink.url} >
                      <a className="text-cyan hover:text-cyan-dark">
                        {blok.blogButtonText}
                        <span aria-hidden="true">→</span>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default CategoryDropdown;
