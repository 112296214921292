import React from 'react';
import { Popover } from "@headlessui/react";
import { transformImage } from 'utils/image.helper';
import DynamicComponent from './DynamicComponent';
import { getTranslatedSlug } from 'utils/router.helper';
import Link from 'next/link';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const EndOfPageActions = ({ blok }) => {

  return (
    <div className="bg-white">
      {/* Header */}
      <div className="relative pb-32 bg-gray-800">
        <div className="absolute inset-0">
          {blok.backgroundimage?.filename && (
            <img
              className="w-full h-full object-cover"
              src={transformImage(
                blok.backgroundimage.filename,
                '2000x0/filters:quality(60)'
              )}
              alt=""
            />
          )}
          <div className="absolute inset-0 bg-gray-500 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
            {blok.title}
          </h1>
        </div>
      </div>

      {/* Overlapping cards */}
      <section className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8" aria-labelledby="contact-heading">
        <h2 className="sr-only" id="contact-heading">Contact us</h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {blok.action_cards.map(link => (
            <div key={link.title} className="flex flex-col bg-white rounded-2xl shadow-xl">
              <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                <div className="absolute top-0 p-4 inline-block bg-cyan rounded-xl shadow-lg transform -translate-y-1/2">
                  <img className="h-8 w-8 text-white" aria-hidden="true" src={link.icon.filename} alt="" />
                </div>
                <h3 className="text-xl font-medium text-gray-900">{link.title}</h3>
                <p className="mt-4 text-base text-gray-500">{link.description}</p>
              </div>
              <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                {link.isNewsletter === true ? (
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button className={`group rounded-md inline-flex items-center text-cyan font-medium hover:text-cyan-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-dark ${open ? 'text-gray-900' : 'text-gray-500'}`}>
                          <span>{link.buttonText}</span>
                          <ChevronDownIcon className={`ml-2 h-5 w-5 group-hover:text-cyan-dark ${open ? 'text-cyan-dark' : 'text-cyan'}`} aria-hidden="true" />
                        </Popover.Button>
                          <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                            {link.form && link.form.length > 0 && link.form.map(item => (
                              <DynamicComponent key={item._uid} blok={item} />
                            ))}
                          </Popover.Panel>
                        </>
                    )}
                  </Popover>
                ) : (
                  <Link href={link.link.url}>
                    <a className="text-base font-medium text-cyan hover:text-cyan-dark">
                      {link.buttonText}
                      <span aria-hidden="true"> →</span>
                    </a>
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default EndOfPageActions