import { isDev } from 'constants/env';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { formatDate } from 'utils/date.helper';
import { transformImage } from 'utils/image.helper';
import { getTranslatedSlug } from 'utils/router.helper';
import Storyblok from 'utils/storyblok';


const ReferenceOverview = ({ locale }) => {
  const [story, setStory] = useState({ total: 0, data: { stories: [] } });

  useEffect(() => {
    getStory();
  }, []);

  const getStory = async () => {
    const sbParam = {
      version: isDev() ? "draft" : "published",
      language: locale,
      starts_with: `referenz/`,
      sort_by: "first_published_at:desc",

    }
    try {
      const referencesOverviewData = await Storyblok.get(
        "cdn/stories",
        sbParam
      )
      setStory(referencesOverviewData)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-7xl mx-auto">
        <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-1 lg:max-w-none">
          {story.data.stories.map((post) => {
            return (
            <Link href={getTranslatedSlug(post.full_slug)} key={post.uuid}
            >
              <a
                className="flex flex-col rounded-lg shadow-lg overflow-hidden"
              >
                <div className="bg-white py-0 lg:py-0">
                  <div className="relative max-w-7xl mx-auto px-0 sm:px-6 lg:px-0">
                    <div className="relative py-24 px-4 overflow-hidden">
                      <div className="absolute inset-0 opacity-70">
                        {post.content?.coverimage ? (
                          <img
                            src={transformImage( post.content.coverimage?.filename || post.content.coverimage, '1600x0/filters:quality(40)')}
                            alt=""
                            className="w-full h-full object-cover"
                          />
                        ) : null}
                      </div>
                      <div className="relative lg:col-span-1">
                        {post.content?.companyLogo?.filename ? (
                          <img
                            className="h-20 w-auto"
                            src={transformImage(post.content.companyLogo.filename, '160x0/filters:quality(60)')}
                            alt=""
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-cyan">
                      {post.category}
                    </p>
                      <div className="block mt-2">
                        <p className="text-xl font-semibold text-gray-900">
                          {post.content.name}
                        </p>
                        <p className="mt-3 text-base text-gray-500">
                          {post.content.intro}
                        </p>
                      </div>
                  </div>
                  <div className="mt-6 flex items-center">
                    {post.content?.authorImage?.filename ? (
                      <div className="flex-shrink-0">
                        <span className="sr-only">{post.author}</span>
                        <img
                          className="h-10 w-10 rounded-full"
                          src={transformImage(post.content.authorImage.filename, '80x0/filters:quality(60)')}
                          alt=""
                        />
                      </div>
                    ) : null}
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900">
                        {post.content.author}
                      </p>
                      <div className="flex space-x-1 text-sm text-gray-500">
                        <time dateTime={post.first_published_at}>
                          {formatDate(post.first_published_at, locale)}
                        </time>
                        <span aria-hidden="true"> &middot; </span>
                        <span> {post.content.readingTime} Min </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </Link>
          )})}
        </div>
      </div>
    </div>
  );
};


export default ReferenceOverview;

