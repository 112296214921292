import Link from 'next/link';
import React from 'react';
import { render } from "storyblok-rich-text-react-renderer";
import { transformImage } from 'utils/image.helper';
import { getTranslatedSlug } from 'utils/router.helper';


const HeroSplit = ({ blok }) => {
  return (
    <div className="bg-white pb-8 sm:pb-12 lg:pb-12">
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div>
              {blok.topIcon?.filename && (
                <img
                  className="h-11 w-auto"
                  src={blok.topIcon.filename}
                  alt="Feature"
                />
              )}
            </div>
            <div className="mt-8">
              {blok.newLabel && blok.newLabel.length && (
                <div>
                  <span className="rounded bg-amber-light px-2.5 py-1 text-xs font-semibold text-amber-dark tracking-wide uppercase">
                    {blok.newLabel}
                  </span>
                </div>
              )}
              <div className="mt-6 sm:max-w-xl">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  {blok.title}
                </h1>
                <div
                  className="mt-6 text-xl text-gray-500"
                >
                  {render(blok.descriptionText)}
                </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center md:justify-start">
                <div className="rounded-md shadow">
                  {blok.signupBtnLink.url && (
                    <Link href={getTranslatedSlug(blok.signupBtnLink)} >
                    <a
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-cyan hover:bg-cyan-dark md:py-4 md:text-lg md:px-10"
                    >
                      {blok.signupBtnText}
                    </a>
                    </Link>
                  )}
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  {blok.demoBtnLink.url && (
                    <Link href={getTranslatedSlug(blok.demoBtnLink)} >
                    <a
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-cyan bg-cyan-light hover:bg-cyan-dark md:py-4 md:text-lg md:px-10"
                    >
                      {blok.demoBtnText}
                    </a>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x="0"
                    y="0"
                    width={blok.pattern === 'grid' ? '100' : '42'}
                    height={blok.pattern === 'grid' ? '100' : '44'}
                    patternUnits="userSpaceOnUse"
                  >
                    {/* <!-- Grid SVG --> */}
                    {blok.pattern === 'grid' && <svg
                      className="text-gray-200"
                      xmlns="http://www.w3.org/2000/svg"
                      width="100"
                      height="100"
                      viewBox="0 0 100 100"
                    >
                      <g fillRule="evenodd">
                        <g fill="currentColor">
                          <path
                            opacity=".5"
                            d="M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z"
                          />
                          <path d="M6 5V0H5v5H0v1h5v94h1V6h94V5H6z" />
                        </g>
                      </g>
                    </svg>}
                    {/* <!-- Bricks SVG --> */}
                    {blok.pattern === 'bricks' && <svg
                      className="text-gray-200"
                      width="42"
                      height="44"
                      viewBox="0 0 42 44"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Page-1" fill="none" fillRule="evenodd">
                        <g id="brick-wall" fill="currentColor">
                          <path
                            d="M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z"
                          />
                        </g>
                      </g>
                    </svg>}
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                />
              </svg>
            </div>
            <div className="
              relative
              px-4
              sm:max-w-3xl
              sm:px-0
              lg:max-w-none
              lg:h-full
              lg:pl-12
            "
            >
              {blok.image?.filename && (
                <img
                  className="
                    mx-auto
                    lg:mx-0
                    rounded-md
                    shadow-xl
                    ring-1 ring-black ring-opacity-5
                  "
                  src={transformImage(blok.image.filename, '1000x0/filters:quality(60)')}
                  alt="Feature"
                  width={blok.imageSize ? blok.imageSize : '490'}
                />
              )}
              <figcaption className="mt-3 flex text-sm text-gray-500">
                {blok.bottomImageCaption}
              </figcaption>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSplit;

