export const BroadTasks = (props) => {
  return (
    <svg
      width="63"
      height="61"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1048 1000"
      {...props}
    >
      <defs>
        <style>
          {
            ".broad-tasks-cls-2,.broad-tasks-cls-4{fill:none;stroke:#000}.broad-tasks-cls-2{stroke-miterlimit:10;stroke-width:32px}.broad-tasks-cls-4{stroke-linecap:round;stroke-linejoin:round;stroke-width:22.29px}"
          }
        </style>
      </defs>
      <path
        d="M261.3 244.24V831a43 43 0 0 0 43 43h419.51a43 43 0 0 0 43-43V125.48a43 43 0 0 0-43-43H423.34A43 43 0 0 0 393 95L274 213.76a43 43 0 0 0-12.7 30.48Z"
        style={{
          fill: "#f9a01f",
        }}
      />
      <path
        className="broad-tasks-cls-2"
        d="M204.3 244.24V831a43 43 0 0 0 43 43h476.51a43 43 0 0 0 43-43V125.48a43 43 0 0 0-43-43H366.34A43 43 0 0 0 336 95L217 213.76a43 43 0 0 0-12.7 30.48Z"
      />
      <path
        className="broad-tasks-cls-2"
        d="M379.1 84.48v118.1a46.19 46.19 0 0 1-46.19 46.19H204.63"
      />
      <path
        style={{
          strokeWidth: "27.86px",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          fill: "none",
          stroke: "#000",
        }}
        d="M464 326h199v128H464z"
      />
      <circle className="broad-tasks-cls-4" cx={336} cy={704} r={52} />
      <circle className="broad-tasks-cls-4" cx={613} cy={704} r={52} />
      <path
        className="broad-tasks-cls-4"
        d="M427 417s-94 68-94 193c34-27 53-53 53-53M333 610l-50.5-50.5M415 701h115l-46.5-46.5M530 701l-44 44M625 610V495l-46.5 46.5M625 495l44 44"
      />
    </svg>
  );
};
