import { XMarkIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import CrafthuntLogo from "../assets/crafthunt_logo.svg";

const NewsBanner = ({ text, mobileText }) => {
  const [showBanner, setShowBanner] = useState(true);

  return (
    showBanner && (
      <div className="relative bg-crafthuntPrimary">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium text-white">
              <span className="md:hidden">{mobileText}</span>
              <span className="hidden md:inline">{text}</span>
              <span className="block sm:ml-2 sm:inline-block">
                <a
                  href="https://crafthunt.app"
                  className="text-accent font-bold underline text-crafthuntSecondary"
                >
                  <CrafthuntLogo className="inline w-5" />Crafthunt
                  <span aria-hidden="true">&rarr;</span>
                </a>
              </span>
            </p>
          </div>
          <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
            <button
              type="button"
              className="flex p-2 rounded-md hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-white"
              onClick={() => setShowBanner(false)}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default NewsBanner;
