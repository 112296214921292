export const PrivateProjects = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1048 1000"
      width="63"
      height="61"
      {...props}
    >
      <defs>
        <style>
          {
            ".private-projects-cls-3,.private-projects-cls-4{fill:none;stroke:#000;stroke-linejoin:round;stroke-width:30px}.private-projects-cls-4{stroke-linecap:round}"
          }
        </style>
      </defs>
      <path
        transform="rotate(45 817.747 364.482)"
        style={{
          fill: "#0297a7",
        }}
        d="M684.96 280.37h265.56v168.22H684.96z"
      />
      <path
        transform="rotate(-45 181.919 414.807)"
        style={{
          fill: "#fec111",
        }}
        d="M51.18 330.7h261.48v168.22H51.18z"
      />
      <path
        className="private-projects-cls-3"
        transform="rotate(-45 207.816 388.92)"
        d="M40.46 304.82h334.71v168.22H40.46z"
      />
      <path
        className="private-projects-cls-3"
        transform="rotate(45 842.195 388.918)"
        d="M674.84 304.82h334.71v168.22H674.84z"
      />
      <path
        className="private-projects-cls-3"
        d="m684.19 349.42-165 62.51a142.53 142.53 0 0 0-50.3 32.5l-53.65 53.66a56.29 56.29 0 0 0-16.59 44.68c1.37 15.17 9.05 31 33.33 36.14a65.5 65.5 0 0 0 58-16.42c31.46-29.26 62.57-55 100.59-26.26C635.71 570.39 767.47 707 767.47 707M272.83 690.44l-101.56-147.4"
      />
      <path
        className="private-projects-cls-3"
        d="m674.11 613.4 90 90a44.44 44.44 0 0 1 0 62.66l-.3.3C747.25 783 720.35 783.31 703 768c15.31 17.35 14.88 44.33-1.7 60.91l-.3.3a44.44 44.44 0 0 1-62.66 0l-.12-.12a44.6 44.6 0 0 1 0 62.9l-.54.54a44.6 44.6 0 0 1-62.9 0 44.6 44.6 0 0 1 0 62.9l-.54.54a44.64 44.64 0 0 1-55.94 5.68l-2.75-2.29M763.81 703.37l116.82-158.16"
      />
      <path
        className="private-projects-cls-4"
        d="m638.15 829.02-58.56-58.55M703 768l-63.63-63.41"
      />
      <path
        className="private-projects-cls-3"
        d="M353.81 359.87c2.53-.53 152.65 57.64 152.65 57.64"
      />
      <rect
        className="private-projects-cls-3"
        x={248.98}
        y={669.06}
        width={130.54}
        height={90.58}
        rx={45.29}
        ry={45.29}
        transform="rotate(-45 314.254 714.352)"
      />
      <rect
        className="private-projects-cls-3"
        x={313.03}
        y={733.11}
        width={130.54}
        height={90.58}
        rx={45.29}
        ry={45.29}
        transform="rotate(-45 378.304 778.401)"
      />
      <rect
        className="private-projects-cls-3"
        x={377.08}
        y={797.16}
        width={130.54}
        height={90.58}
        rx={45.29}
        ry={45.29}
        transform="rotate(-45 442.342 842.446)"
      />
      <rect
        className="private-projects-cls-3"
        x={441.13}
        y={861.2}
        width={130.54}
        height={90.58}
        rx={45.29}
        ry={45.29}
        transform="rotate(-45 506.393 906.495)"
      />
      <path
        style={{
          strokeMiterlimit: 10,
          strokeWidth: 32,
          fill: "none",
          stroke: "#000",
        }}
        d="m574.78 892.53-10.13-10.13"
      />
      <circle cx={143} cy={453} r={30} />
      <circle cx={775} cy={325} r={30} />
    </svg>
  );
};
