import { useState, useEffect } from "react";
import Image from "next/image";
import { isDev } from "constants/env";
import Storyblok from "utils/storyblok";
import { useRouter } from "next/router";
import { getTranslatedSlug } from "utils/router.helper";
import Link from "next/link";

const IntegrationsOverview = () => {
  const router = useRouter();
  const lang = router.locale
  const [story, setStory] = useState({ total: 0, data: { stories: [] } });

  useEffect(() => {
    getStory();
  }, [lang]);

  const getStory = async () => {
    const sbParam = {
      version: isDev() ? "draft" : "published",
      language: lang,
      starts_with: 'integration',
    }
    try {
      const integrationOverviewData = await Storyblok.get(
        "cdn/stories",
        sbParam
      )
      setStory(integrationOverviewData)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="relative bg-gray-50 pt-8 pb-16 px-4 sm:px-6 lg:pt-16 lg:pb-24 lg:px-8">
      <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
        {story.data.stories.map((action, actionIdx) => (
          <div
            key={action.content.title}
            className={`relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan ${actionIdx === 0 ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none" : ""
              } ${actionIdx === 1 ? "sm:rounded-tr-lg" : ""} ${actionIdx === story.data.stories.length - 2 ? "sm:rounded-bl-lg" : ""
              } ${actionIdx === story.data.stories.length - 1 ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none" : ""
              }`}
          >
            <div>
              <span className="rounded-lg inline-flex py-2 ring-4 ring-white">
                <img
                  className="h-8"
                  src={action.content.logo.filename}
                  alt="Molteo"
                />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                <Link href={getTranslatedSlug(action.full_slug)} >
                <a className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.content.title}
                </a>
                </Link>
              </h3>
              <p className="mt-2 mb-2 text-sm text-gray-500">{action.content.intro}</p>
              <div className="flex flex-wrap">
                {action.content.categories.map((item) => (
                  <span
                    key={item}
                    className="inline-flex items-center px-3 mr-1 my-1 py-0.5 rounded-full text-sm font-medium bg-gray-200 text-gray-800"
                  >
                    {item}
                  </span>
                ))}
              </div>
            </div>
            <span
              className="pointer-events-none absolute top-6 right-6 text-gray-400 group-hover:text-gray-500"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntegrationsOverview;
