import DynamicComponent from "./DynamicComponent";
import { sbEditable } from "@storyblok/storyblok-editable";

const Page = ({ blok, locale, translations, settings }) => (
  <main {...sbEditable(blok)} key={blok._uid}>
    {blok.body
      ? blok.body.map((blok) => (
          <DynamicComponent
            key={blok._uid}
            blok={blok}
            locale={locale}
            translations={translations}
            settings={settings}
          />
        ))
      : null}
  </main>
);

export default Page;
