const SvgComponent = (props) => (
  <svg
    fill="none"
    width="63"
    height="61"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1048 1000"
    {...props}
  >
    <path
      d="m656.724 841-184-102-301.12 149.771 45.461-315.11L16.924 351.627 339.724 299l123-258 78.115 303.157 275.31 44.6-200.972 182.967L656.724 841Z"
      fill="#F9A01F"
    />
    <path
      d="M759.868 885.9 476.369 738.606 194.31 888.645l52.478-315.143L16.924 351.608l315.936-47.475 140-287.175 142.781 285.8 316.38 44.406-227.687 224.112L759.868 885.9Z"
      stroke="#1C0E05"
      strokeWidth={32}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
