const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1022.84 943.48"
    width="63"
    height="61"
    {...props}
  >
    <title>{"Asset 1111"}</title>
    <g>
      <g>
        <path
          d="M973.58 375.59c14.78 0 26.76 13.43 26.76 30v357.74c0 16.57-12 30-26.76 30h-31c-14.78 0-26.75 13.43-26.75 30v67.59c0 26.73-28.82 40.11-45.68 21.21l-112.11-104a25.35 25.35 0 0 0-18.91-8.79l-205.92-6c-14.77 0-26.75-13.43-26.75-30V677h258.65c59.07 0 106.95-46.4 106.95-112.63 0 0 1.34-149.87 1.34-187.87 25.86-.02 100.18-.91 100.18-.91Z"
          style={{
            fill: "#0297a7",
          }}
        />
        <path
          d="M767.29 72H136.81c-21.16 0-38.31 17.77-38.31 39.7v473.37c0 21.93 17.15 39.7 38.31 39.7h44.33c21.16 0 38.31 17.77 38.31 39.7v89.44c0 35.36-7.74 45.08 16.4 20.07L376.32 628.4a37.64 37.64 0 0 1 27.09-11.63l363.88 8c21.16 0 38.31-17.77 38.31-39.7V111.68c0-21.93-17.15-39.68-38.31-39.68Z"
          style={{
            fill: "#f9a01f",
          }}
        />
        <path
          d="M902 381.59h74.31a30 30 0 0 1 30 30v357.74a30 30 0 0 1-30 30h-34.69a30 30 0 0 0-30 30v67.59c0 26.73-32.32 40.11-51.22 21.21l-110-110a30 30 0 0 0-21.21-8.79H482.58a30 30 0 0 1-30-30v-51.07"
          style={{
            strokeLinecap: "round",
            fill: "none",
            stroke: "#000",
            strokeMiterlimit: 10,
            strokeWidth: 33,
          }}
        />
        <path
          d="M762.85 16.5H59.25A42.75 42.75 0 0 0 16.5 59.25V569a42.75 42.75 0 0 0 42.75 42.75h49.48a42.75 42.75 0 0 1 42.75 42.75v96.31c0 38.09 46 57.16 73 30.23l156.74-156.73a42.76 42.76 0 0 1 30.23-12.52h351.4A42.75 42.75 0 0 0 805.6 569V59.25a42.75 42.75 0 0 0-42.75-42.75Z"
          style={{
            fill: "none",
            stroke: "#000",
            strokeMiterlimit: 10,
            strokeWidth: 33,
          }}
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
