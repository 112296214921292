export const GoodWorktime = (props) => {
  return (
    <svg
      width="63"
      height="61"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1048 1000"
      {...props}
    >
      <defs>
        <style>
          {
            ".good-worktime-cls-3{fill:none;stroke:#000;stroke-miterlimit:10;stroke-linecap:round;stroke-width:32px}"
          }
        </style>
      </defs>
      <path
        d="M933 500c0 263.72-196.66 477.5-439.25 477.5S54.5 763.72 54.5 500 300.26 24 542.86 24 933 236.28 933 500Z"
        style={{
          fill: "#f9a01f",
        }}
      />
      <circle
        cx={532}
        cy={500}
        r={477.5}
        style={{
          strokeWidth: 36,
          strokeMiterlimit: 10,
          fill: "none",
          stroke: "#000",
        }}
      />
      <path
        className="good-worktime-cls-3"
        d="M532 23v69M294.05 85.95l34.5 59.76M119.45 259.45l59.76 34.5M55 497h69M117.95 734.95l59.76-34.5M291.45 909.55l34.5-59.76M529 974v-69M766.95 911.05l-34.5-59.76M941.55 737.55l-59.76-34.5M1006 500h-69M943.05 262.05l-59.76 34.5M769.55 87.45l-34.5 59.76"
      />
      <path
        d="M532 498V186.5c173.14 0 313.5 140.36 313.5 313.5Z"
        style={{
          fill: "#00bcd4",
        }}
      />
      <path
        d="M532 498V186.5c173.14 0 313.5 140.36 313.5 313.5Z"
        style={{
          strokeLinejoin: "round",
          strokeWidth: 28,
          fill: "none",
          stroke: "#000",
        }}
      />
    </svg>
  );
};
