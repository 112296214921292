export const CompanyTravel = (props) => {
  return (
    <svg
      width="63"
      height="61"
      viewBox="0 0 741 891"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M714.922 871.5C670.322 751.148 533.084 663.5 370.701 663.5C208.318 663.5 71.0855 751.148 26.4805 871.5H714.922Z"
        fill="#F9A01F"
      />
      <path
        d="M372.701 110.831C371.701 87.0112 372.701 74.7171 377.113 46.7311C378.128 40.2911 376.391 31.5802 384.579 29.9972C389.445 29.0562 394.17 31.497 398.715 33.128C413.68 38.507 431.469 35.4149 446.672 30.4449C461.875 25.4749 476.357 18.664 492.638 16.506C507.064 14.594 521.861 16.4909 536.158 18.9059C569.515 24.5409 603.751 33.8703 625.966 53.8063C642.938 69.0343 651.015 88.7442 661.267 107.182C671.519 125.62 680.752 170.337 704.315 178.95L701.466 180.237C663.198 191.059 623.6 161.712 588.78 145.404C574.001 138.482 560.94 129.394 545.371 123.583C514.296 111.983 475.547 115.312 446.56 129.754C437.997 134.021 430.074 139.22 420.592 142.124C405.022 146.895 389.456 150.701 376.705 142.339L372.701 110.831Z"
        fill="#0297A7"
      />
      <path
        d="M714.922 871.5C670.322 723.664 533.084 616 370.701 616C208.318 616 71.0855 723.664 26.4805 871.5H714.922Z"
        stroke="black"
        strokeWidth="38"
        strokeMiterlimit="10"
      />
      <path
        d="M370.701 18.5V615.5"
        stroke="black"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M372.701 139.5C371.701 108.5 372.701 92.5002 377.113 56.0742C378.128 47.6922 376.391 36.3559 384.579 34.2959C389.445 33.0709 394.17 36.2441 398.715 38.3701C413.68 45.3701 431.469 41.3459 446.672 34.8779C461.875 28.4099 476.357 19.5467 492.638 16.7377C507.064 14.2497 521.861 16.7188 536.158 19.8618C569.515 27.1958 603.751 39.3367 625.966 65.2797C642.938 85.0987 651.015 110.749 661.267 134.745C671.519 158.741 685.752 183.099 709.315 194.309L706.466 195.983C668.198 210.068 623.6 205.712 588.78 184.489C574.001 175.48 560.94 163.652 545.371 156.089C514.296 140.995 475.547 145.325 446.56 164.12C437.997 169.673 430.074 176.439 420.592 180.22C405.022 186.429 389.456 191.382 376.705 180.5L372.701 139.5Z"
        stroke="black"
        strokeWidth="30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
