import Image from "next/image";
import { transformImage } from "utils/image.helper";

const PageHeader = ({ blok }) => {
  return (
    <div className="relative bg-gray-500">
      <div className="absolute inset-0">
        {blok.image?.filename && (
          <Image
            src={transformImage(blok.image.filename, "2000x0/filters:quality(60)")}
            alt=""
            layout="fill"
            objectFit="cover"
          />
        )}
        <div className="absolute inset-0 bg-gray-500 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">{blok.title}</h1>
        <p className="mt-6 text-xl text-gray-200 max-w-3xl">{blok.description}</p>
      </div>
    </div>
  );
};

export default PageHeader;
