const Excavator = (props) => (
  <svg
    width="63"
    height="61"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1048 1000"
    {...props}
  >
    <defs>
      <style>
        {
          ".excavator-cls-3{fill:none;stroke:#333;stroke-linecap:round;stroke-linejoin:round;stroke-width:26px}"
        }
      </style>
    </defs>
    <rect
      x={152}
      y={718.74}
      width={567.44}
      height={146.59}
      rx={66.56}
      ry={66.56}
      style={{
        fill: "#f9a01f",
      }}
    />
    <path
      d="M348.77 329.71v104.58c0 19.3-13.53 34.94-30.21 34.94H137.21c-16.68 0-30.21 15.64-30.21 34.93v120.49c0 19.3 13.53 34.94 30.21 34.94h423.12c16.68 0 30.21-15.64 30.21-34.94V329.71c0-19.29-13.53-34.94-30.21-34.94H379c-16.71 0-30.23 15.65-30.23 34.94Z"
      style={{
        fill: "#0297a7",
      }}
    />
    <rect
      className="excavator-cls-3"
      x={31.33}
      y={718.74}
      width={688.11}
      height={146.59}
      rx={73.29}
      ry={73.29}
    />
    <circle className="excavator-cls-3" cx={111.15} cy={792.03} r={33.07} />
    <circle className="excavator-cls-3" cx={646.06} cy={792.03} r={33.07} />
    <path
      className="excavator-cls-3"
      d="M217.94 792.03h328.48M285 659.59h179.93v59.15H285zM482.96 294.77v226.19M590.54 568.37l-215.16-91.19V294.77"
    />
    <path
      className="excavator-cls-3"
      d="M310.93 329.71v104.58A34.93 34.93 0 0 1 276 469.23H66.27a34.93 34.93 0 0 0-34.94 34.93v120.49a34.94 34.94 0 0 0 34.94 34.94H555.6a34.94 34.94 0 0 0 34.94-34.94V329.71a34.94 34.94 0 0 0-34.94-34.94H345.87a35 35 0 0 0-34.94 34.94ZM734 240 590.54 459.71V568l.71.71 171.67-260.23a54.51 54.51 0 0 1 45.5-24.48h98.2v-69l-127.12.34A54.54 54.54 0 0 0 734 240ZM906.62 497v190.52a15.92 15.92 0 0 0 21 15.18c30.4-10.46 83.13-37.59 83.13-100C1010.71 497 906.62 497 906.62 497Z"
    />
    <path
      className="excavator-cls-3"
      d="M939.8 215h-33.18v282s38.76.14 69 24.19V250.8A35.8 35.8 0 0 0 939.8 215Z"
    />
  </svg>
);

export default Excavator;
