export const Star_big = (props) => {
  return (
    <svg
      width="63"
      height="61"
      viewBox="0 0 949 905"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M656.724 841L472.724 739L171.604 888.771L217.065 573.661L16.9238 351.627L339.724 299L462.724 41L540.839 344.157L816.149 388.757L615.177 571.724L656.724 841Z"
        fill="#F9A01F"
      />
      <path
        d="M759.868 885.9L476.369 738.606L194.31 888.645L246.788 573.502L16.9238 351.608L332.86 304.133L472.86 16.958L615.641 302.758L932.021 347.164L704.334 571.276L759.868 885.9Z"
        stroke="#1C0E05"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
