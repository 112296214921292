import { Mail } from "./Mail";
import { Phone } from "./Phone";
import { Twitter } from "./Twitter";
import { AnnualBonus } from "./AnnualBonus";
import { Star_big } from "./Star_big";
import { BroadTasks } from "./BroadTasks";
import { GoodWorktime } from "./GoodWorktime";
import FamilyBusiness from "./FamilyBusiness";
import { CorporateBusiness } from "./CorporateBusiness";
import { PrivateProjects } from "./PrivateProjects";
import { PublicProjects } from "./PublicProjects";
import { SpezialisedTasks } from "./SpezialisedTasks";
import { TaxBenefits } from "./TaxBenefits";
import { CompanyTravel } from "./CompanyTravel";
import DigitalApp from "./DigitalApp";
import { ArrowLeft } from "./ArrowLeft";
import Excavator from "./Excavator";
import FourDayWorkWeek from "./FourDayWorkWeek";
import Gear from "./Gear";
import Health from "./Health";
import MultiLingual from "./MultiLingual";
import OfficialDocs from "./OfficialDocs";
import Star from "./Star";
import Telescope from "./Telescope";
import Map from "./map.svg";
import GermanFlag from "./flags/german";
import AmericanFlag from "./flags/american";
import PolishFlag from "./flags/polish";
import CzechFlag from "./flags/czech";
import Romanianflag from "./flags/romanian";
import Spanishflag from "./flags/spanish";
import Frenchflag from "./flags/french";
import Greekflag from "./flags/greek";
import Italianflag from "./flags/italian";
import DanishFlag from "./flags/danish";
import BrasilFlag from "./flags/brasil";
import {
  CalendarIcon,
  ArrowsRightLeftIcon,
  UserGroupIcon,
  BuildingOfficeIcon,
  BanknotesIcon,
} from "@heroicons/react/20/solid";
import { SVGProps } from "react";

const Components = {
  twitter: Twitter,
  mail: Mail,
  phone: Phone,
  annualbonus: AnnualBonus,
  annualparty: Star_big,
  map: Map,
  goodwork: GoodWorktime,
  broadtasks: BroadTasks,
  familybusiness: FamilyBusiness,
  corp: CorporateBusiness,
  privateprojects: PrivateProjects,
  publicprojects: PublicProjects,
  spezializedtasks: SpezialisedTasks,
  taxbenefits: TaxBenefits,
  training: Telescope,
  companytrip: CompanyTravel,
  germanflag: GermanFlag,
  americanflag: AmericanFlag,
  polishflag: PolishFlag,
  romanianflag: Romanianflag,
  spanishflag: Spanishflag,
  frenchflag: Frenchflag,
  greekflag: Greekflag,
  italianflag: Italianflag,
  czechflag: CzechFlag,
  danishflag: DanishFlag,
  brasilflag: BrasilFlag,
  digitalapp: DigitalApp,
  innovation: Excavator,
  fourdayweek: FourDayWorkWeek,
  ebike: Gear,
  health: Health,
  multilingual: MultiLingual,
  star: Star_big,
  officialdocs: OfficialDocs,
  kita: Star,
  "year-unit": CalendarIcon,
  "km-unit": ArrowsRightLeftIcon,
  "employee-unit": UserGroupIcon,
  "month-unit": CalendarIcon,
  "project-unit": BuildingOfficeIcon,
  "currency-unit": BanknotesIcon,
  arrowleft: ArrowLeft,
};

type DynamicIconProps = {
  type?: string | null;
} & SVGProps<SVGSymbolElement>;

const DynamicIcon = ({ type, ...rest }: DynamicIconProps) => {
  if (typeof Components[type] !== "undefined") {
    const Component = Components[type] as React.ElementType;
    if (!Component) return null;
    return <Component {...rest} />;
  }
  return null;
};

export default DynamicIcon;
