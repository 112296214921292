export const TaxBenefits = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1048 1000"
      width="63"
      height="61"
      {...props}
    >
      <defs>
        <style>
          {
            ".tax-cls-1{fill:#f9a01f}.tax-cls-2{fill:none;stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:36px}"
          }
        </style>
      </defs>
      <ellipse
        className="tax-cls-1"
        cx={245.24}
        cy={809.9}
        rx={129.76}
        ry={157.48}
      />
      <ellipse
        className="tax-cls-1"
        cx={691.5}
        cy={806.91}
        rx={129.5}
        ry={157.48}
      />
      <circle className="tax-cls-2" cx={272.97} cy={809.9} r={157.48} />
      <path className="tax-cls-2" d="M432.04 806.49 759 150" />
      <circle className="tax-cls-2" cx={722.53} cy={810.82} r={157.48} />
      <path className="tax-cls-2" d="M563.46 807.4 228 142" />
      <path d="M538.41 221.31h-49.55q.79 20.9 11.36 30.85t34.85 10q15.72 0 27.95-4.77l6.83 42.86a189.13 189.13 0 0 1-41.76 4.75q-42.89 0-68.37-22.23T433 221.31h-26.2v-25.4H433v-19.22h-26.2v-25.4h26.67q3-39.39 29.54-61.46t70-22.07a155.17 155.17 0 0 1 36.84 4.92L563 115.56a71 71 0 0 0-26.52-4.93q-21.91 0-33.5 9.93t-13.66 30.73h49.07v25.4h-49.53v19.22h49.55Z" />
    </svg>
  );
};
