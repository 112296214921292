import Link from "next/link";
import { useState } from "react";
import { render } from "storyblok-rich-text-react-renderer";
import { transformImage } from "utils/image.helper";
import { getTranslatedSlug } from "utils/router.helper";

const Integrations = ({ blok }) => {
  const [clickedImageIdx, setClickedImageIdx] = useState(0)

  return (
    <>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-left">
            {blok?.logo?.filename && (
              <img
                className="h-20"
                src={
                  transformImage(
                    blok.logo.filename,
                    "400x0/filters:quality(70)"
                  )
                }
                alt={blok.title}
              />
            )}
            <h1
              className={`
                mt-16
                text-4xl
                font-extrabold
                text-gray-900
                sm:text-5xl
                sm:tracking-tight
                lg:text-6xl
              `}
            >
              {blok.title}
            </h1>
            <p
              className={`
                text-base
                font-semibold
                my-3
                text-indigo-600
                tracking-wide
                uppercase
              `}
            >
              {blok.categories.map((category) => (
                <span
                  key={category}
                  className={`
                    inline-flex
                    items-center
                    px-3
                    py-1
                    mx-1
                    mb-3
                    rounded-full
                    text-sm
                    font-medium
                    bg-gray-400
                    text-white
                  `}
                >
                  {category}
                </span>
              ))}
              <span
                className={`
                  inline-flex
                  items-center
                  px-3
                  py-1
                  mx-1
                  mb-3
                  rounded-full
                  text-sm
                  font-medium
                  bg-amber
                  text-gray-900
                `}
              >
                {blok.softwareType}
              </span>
            </p>
            <p className="max-w-xl text-xl text-left text-gray-500">
              {blok.intro}
            </p>
            <div className="mt-5 sm:mt-4 sm:flex sm:justify-center md:justify-start items-center space-x-5">
              <div className="rounded-md shadow">
                <a
                  href="https://app.molteo.de/signup"
                  className={`
                    w-full
                    flex
                    items-center
                    justify-center
                    px-8
                    py-2
                    border border-transparent
                    text-base
                    font-medium
                    rounded-md
                    text-white
                    bg-cyan
                    hover:bg-cyan-dark
                    md:py-4
                    md:text-lg
                    md:px-10
                  `}
                >
                  {blok.signUpButton}
                </a>
              </div>
              {blok?.introCallToActionLink?.url && blok?.introCallToActionText && (
                <Link href={getTranslatedSlug(blok.introCallToActionLink.url)} >
                  <a
                    className="text-base font-bold text-cyan hover:text-cyan-dark"
                    target="_blank"
                  >
                    {blok.introCallToActionText}
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </Link>
              )}
            </div>
          </div> 
        </div>
      </div>
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div>
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="shadow-lg sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-cyan-light space-y-6 sm:p-6">
                    {blok?.screenshots?.length &&
                      blok.screenshots[clickedImageIdx]?.filename && (
                        <div className="aspect-w-3 aspect-h-2">
                          <img
                            className="object-fit rounded-lg"
                            src={`${
                              blok.screenshots[clickedImageIdx].filename
                            }?fit=max&w=1200&q=60`}
                            alt=""
                          />
                        </div>
                      )}
                  </div>
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <ul
                      role="list"
                      className="
                        grid grid-cols-2
                        gap-x-4 gap-y-8
                        sm:grid-cols-3
                        sm:gap-x-6
                        lg:grid-cols-4
                        xl:gap-x-8
                      "
                    >
                      {blok?.screenshots?.length ?  blok.screenshots.map((file, idx) => (
                        <li key={file.filename} className="relative">
                          <div
                            className="
                              group
                              block
                              w-full
                              aspect-w-3 aspect-h-2
                              rounded-lg
                              bg-gray-100
                              focus-within:ring-2
                              focus-within:ring-offset-2
                              focus-within:ring-offset-gray-100
                              focus-within:ring-indigo-500
                              overflow-hidden
                            "
                            onClick={() => setClickedImageIdx(idx)}
                          >
                            <img
                              src={`${
                                file.filename
                              }?fit=max&w=300&q=30`}
                              alt=""
                              className="
                                object-cover
                                pointer-events-none
                                group-hover:opacity-75
                              "
                            />
                          </div>
                        </li>
                      )) : null}
                    </ul>
                    <div
                      className="prose text-gray-500"
                    >
                      {render(blok.howItWorks)}
                    </div>
                    {blok?.url?.url && blok?.urlText && (
                      <div>
                        <Link href={getTranslatedSlug(blok.url)}>
                          <a
                            className="text-base font-bold text-cyan hover:text-cyan-dark"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {blok.urlText}
                            <span aria-hidden="true"> &rarr;</span>
                          </a>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-10">
                  <h3 className="text-lg font-medium text-gray-900">
                    {blok.categoriesTitle}
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {blok.categories.map((category) => (
                      <li key={category} className="flex">
                        <svg
                          className="flex-shrink-0 h-6 w-6 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                        <span className="ml-3 text-sm text-gray-500">
                          {category}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <h3 className="mt-8 text-lg font-medium text-gray-900">
                    {blok.industriesTitle}
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {blok.industries.map((industry) => (
                      <li key={industry} className="flex">
                        <svg
                          className="flex-shrink-0 h-6 w-6 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                        <span className="ml-3 text-sm text-gray-500">
                          {industry}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-cyan-light">
      <div
        className="
          max-w-7xl
          mx-auto
          py-12
          px-4
          sm:px-6
          lg:py-16
          lg:px-8
          lg:flex
          lg:items-center
          lg:justify-between
        "
      >
        <h2
          className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
        >
          <span className="block">{blok.callToActionTitle}</span>
          <span className="block text-cyan-dark">{blok.callToActionText}</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          {blok.callToActionButton1 && (
            <div className="inline-flex rounded-md shadow">
              <Link href={getTranslatedSlug(blok.callToActionButton1)} >
                <a
                  className="
                    inline-flex
                    items-center
                    justify-center
                    px-5
                    py-3
                    border border-transparent
                    text-base
                    font-medium
                    rounded-md
                    text-gray-900
                    bg-amber
                    hover:bg-amber-dark
                  "
                >
                  {blok.callToActionButton1Text}
                </a>
              </Link>
            </div>
          )}
          {blok.callToActionButton2 && (
            <div className="ml-3 inline-flex rounded-md shadow">
              <Link href={getTranslatedSlug(blok.callToActionButton2)} >
                <a
                  className="
                    inline-flex
                    items-center
                    justify-center
                    px-5
                    py-3
                    border border-transparent
                    text-base
                    font-medium
                    rounded-md
                    text-cyan
                    bg-white
                    hover:bg-cyan-dark
                  "
                >
                  {blok.callToActionButton2Text}
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  
  );
};

export default Integrations;
