import Link from "next/link";
import { getTranslatedSlug } from "utils/router.helper";

const Button = ({ blok }) => {
  if (blok.isText === true) {
    return (
      <Link href={blok.destination.url} >
        <a
          className="text-base font-medium text-gray-500 hover:text-gray-900"
        >
          {blok.label}
        </a>
      </Link>
    );
  } else {
    return (
      <Link href={blok.destination.url} >
      <a
        className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-cyan hover:bg-cyan-dark"
      >
        {blok.label}
      </a>
      </Link>
    );
  }
};

export default Button;
