import Head, { HeadProps } from "../../Head";
import Navigation from "../Navigation/Navigation";
import Footer from "../../Footer";
import { TranslatedSlugDef } from "types/translation.type";
import { GetServerSidePropsContext } from "next";
import { hasInApp } from "utils/helpers";
import { useMemo } from "react";
import { CrispChat } from "components/CrispChat/CrispChat";
import { useRouter } from "next/router";

type LayoutProps = {
  children: React.ReactNode;
  locale: string;
  seoObj?: HeadProps["meta"];
  settings?: any;
  translatedSlugs?: TranslatedSlugDef[];
  hideBars?: boolean;
  query?: GetServerSidePropsContext["query"];
  deactivateCrispChat?: boolean;
  deactivateSupportChat?: boolean
};

const Layout = ({
  children,
  locale,
  seoObj,
  settings,
  translatedSlugs,
  hideBars,
  query,
  deactivateCrispChat,
  deactivateSupportChat,
}: LayoutProps) => {
  const router = useRouter();
  const inApp = useMemo(
    () => hasInApp(query, router.asPath),
    [query, router.asPath]
  );

  const showBars = !inApp && !hideBars;

  return (
    <div className="bg-gray-900">
      {!deactivateCrispChat && <CrispChat />}
      <Head meta={seoObj} />
      {showBars && (
        <Navigation
          locale={locale}
          settings={settings}
          
        />
      )}
      <main>{children}</main>
      {showBars && <Footer translatedSlugs={translatedSlugs} />}
    </div>
  );
};

export default Layout;
