import React from 'react';
import { transformImage } from 'utils/image.helper';

const FeatureTextLeft = ({ blok }) => {
  return (
    <>
      {blok.pattern !== '' && (
        <svg
          className="
            hidden
            lg:block
            absolute
            left-full
            transform
            -translate-x-1/2 -translate-y-1/6
          "
          width="404"
          height="600"
          fill="none"
          viewBox="0 0 404 600"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id={`b1e6e422-73f8-40a6-b5d9-c8586e37e0e7+${blok._uid}`}
              x="0"
              y="0"
              width={blok.pattern === 'grid' ? '100' : '42'}
              height={blok.pattern === 'grid' ? '100' : '44'}
              patternUnits="userSpaceOnUse"
            >
              {/* Grid SVG */}
              {blok.pattern === 'grid' && (
                <svg
                  className="text-gray-200"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                >
                  <g fillRule="evenodd">
                    <g fill="currentColor">
                      <path opacity=".5" d="M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z" />
                      <path d="M6 5V0H5v5H0v1h5v94h1V6h94V5H6z" />
                    </g>
                  </g>
                </svg>
              )}
              {/* Bricks SVG */}
              {blok.pattern === 'bricks' && (
                <svg
                  className="text-gray-200"
                  width="42"
                  height="44"
                  viewBox="0 0 42 44"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Page-1" fill="none" fillRule="evenodd">
                    <g id="brick-wall" fill="currentColor">
                      <path
                        d="M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z"
                      />
                    </g>
                  </g>
                </svg>
              )}
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill={`url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7+${blok._uid})`}
          />
        </svg>
      )}

      <div
        className="
          relative
          mt-12
          lg:mt-24
          lg:grid lg:grid-cols-2
          lg:gap-8
          lg:items-center
        "
      >
        <div className="relative">
          <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
            {blok.title}
          </h3>
          <p className="mt-3 text-lg text-gray-500">
            {blok.subtitle}
          </p>

          <dl className="mt-10 space-y-10">
            {blok.advantages.map(item => (
              <div key={item._uid} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan text-white">
                    <img className="h-8 w-8 text-white" aria-hidden="true" src={item.icon.filename} alt="" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    {item.title}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {item.subtitle}
                </dd>
              </div>
            ))}
          </dl>
        </div>

        <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
          <svg
            className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
            width="784"
            height="404"
            fill="none"
            viewBox="0 0 784 404"
          >
            <defs>
              {/* Grid SVG */}
              {blok.pattern === 'grid' && (
                <svg
                  className="text-gray-200"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                >
                  <g fillRule="evenodd">
                    <g fill="currentColor">
                      <path
                        opacity=".5"
                        d="M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z"
                      />
                      <path d="M6 5V0H5v5H0v1h5v94h1V6h94V5H6z" />
                    </g>
                  </g>
                </svg>
              )}
              {/* Bricks SVG */}
              {blok.pattern === 'bricks' && (
                <svg
                  className="text-gray-200"
                  width="42"
                  height="44"
                  viewBox="0 0 42 44"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Page-1" fill="none" fillRule="evenodd">
                    <g id="brick-wall" fill="currentColor">
                      <path
                        d="M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z"
                      />
                    </g>
                  </g>
                </svg>
              )}
              <pattern
                id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width="784" height="404" fill={`url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)`} />
          </svg>
          {blok.contenttype === 'image' && blok.image?.filename && <img
            className="relative mx-auto shadow-lg"
            width={blok.imageSize ? blok.imageSize : '490'}
            src={transformImage(blok.image.filename, '1000x0/filters:quality(80)')}
            alt=""
          />}
          {blok.contenttype === 'video' && <a href={blok.videolink.url}>
            <img
              className="relative mx-auto shadow-lg"
              height="590"
              src={blok.videoplaceholder.url}
            />
          </a>}
        </div>
      </div>
    </>)
};

export default FeatureTextLeft;

