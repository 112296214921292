import React from "react";
import { transformImage } from 'utils/image.helper';

const Trust = ({ blok }) => {
  return (
    <div className="bg-white">
      {/* Logo Cloud */}
      <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
            {blok.title}
          </p>
          <div className="mt-10 grid grid-cols-2 gap-10 md:grid-cols-6 lg:grid-cols-6">
            {blok.logos.map((logo) => (
              <div
                key={logo._uid}
                className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
              >
                {logo?.image?.filename && (
                  <img
                    className="h-24 w-full object-contain"
                    src={
                      transformImage(
                        logo.image.filename,
                        "350x0/filters:quality(60)"
                      )}
                    alt="Customer Logo"
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trust;
