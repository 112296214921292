export const CorporateBusiness = (props) => {
  return (
    <svg
      width="63"
      height="61"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-80 0 1048 1000"
      {...props}
    >
      <defs>
        <style>
          {
            ".corporate-cls-2,.corporate-cls-3{fill:none;stroke:#212121;stroke-miterlimit:10}.corporate-cls-2{stroke-width:32px}.corporate-cls-3{stroke-width:28px}.corporate-cls-5{fill:#b2ebf2}"
          }
        </style>
      </defs>
      <path
        d="M339.22 984H55.77c-18.39 0-33.3-18.15-33.3-40.53v-886C22.47 35.15 37.38 17 55.77 17H445.7c18.39 0 33.3 18.15 33.3 40.53v251.6"
        style={{
          fill: "#00bcd4",
        }}
      />
      <path
        className="corporate-cls-2"
        d="M408 984H63a40.54 40.54 0 0 1-40.53-40.53v-886A40.54 40.54 0 0 1 63 17h474.6a40.54 40.54 0 0 1 40.53 40.53v251.6"
      />
      <rect
        className="corporate-cls-3"
        x={125.55}
        y={300.72}
        width={151.19}
        height={151.19}
        rx={14.66}
        ry={14.66}
      />
      <path
        className="corporate-cls-2"
        d="M367.06 315.38a14.66 14.66 0 0 1 14.66-14.66h121.86a14.66 14.66 0 0 1 14.66 14.66"
      />
      <rect
        className="corporate-cls-3"
        x={125.55}
        y={502.95}
        width={151.19}
        height={151.19}
        rx={14.66}
        ry={14.66}
      />
      <path
        d="M540.43 306.61h-174.7c-18.79 0-34 18.15-34 40.53v596.33c0 22.38 15.22 40.53 34 40.53H764c18.78 0 34-18.15 34-40.53V347.14c0-22.38-15.23-40.53-34-40.53H540.43"
        style={{
          fill: "#fec111",
        }}
      />
      <rect
        className="corporate-cls-5"
        x={420.07}
        y={422.45}
        width={303.93}
        height={89.55}
        rx={20.56}
        ry={20.56}
      />
      <rect
        className="corporate-cls-5"
        x={420.07}
        y={577.57}
        width={303.93}
        height={89.43}
        rx={20.55}
        ry={20.55}
      />
      <rect
        className="corporate-cls-3"
        x={125.55}
        y={108.3}
        width={151.19}
        height={151.19}
        rx={14.66}
        ry={14.66}
      />
      <rect
        className="corporate-cls-3"
        x={367.06}
        y={108.3}
        width={151.19}
        height={151.19}
        rx={14.66}
        ry={14.66}
      />
      <path
        className="corporate-cls-2"
        d="M580.43 306.61H372.25a40.53 40.53 0 0 0-40.53 40.53v596.33A40.53 40.53 0 0 0 372.25 984h474.59a40.53 40.53 0 0 0 40.53-40.53V347.14a40.53 40.53 0 0 0-40.53-40.53H580.43"
      />
      <rect
        className="corporate-cls-3"
        x={420.07}
        y={422.45}
        width={376.98}
        height={90.32}
        rx={23}
        ry={23}
      />
      <rect
        className="corporate-cls-3"
        x={420.07}
        y={577.57}
        width={376.98}
        height={90.32}
        rx={23}
        ry={23}
      />
    </svg>
  );
};
