import { isDev } from 'constants/env';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { formatDate } from 'utils/date.helper';
import { transformImage } from 'utils/image.helper';
import { getTranslatedSlug } from 'utils/router.helper';
import Storyblok from 'utils/storyblok';
import fallbackImage from "../assets/blog-image-placeholder.png";


const BlogOverview = () => {
  const router = useRouter();
  const lang = router.locale
  const [story, setStory] = useState({ total: 0, data: { stories: [] } });

  useEffect(() => {
    getStory();
  }, [lang]);

  const getStory = async () => {
    const sbParam = {
      version: isDev() ? "draft" : "published",
      language: lang,
      starts_with: 'blogs',
      sort_by: 'first_published_at:desc'
    }
    try{
      const blogOverviewData = await Storyblok.get(
        "cdn/stories",
        sbParam
      )
      setStory(blogOverviewData)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-7xl mx-auto">
        <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {story.data.stories.map(post => (
            <Link key={post.name} href={post.full_slug} >
              <a  className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <img
                    className="h-48 w-full object-cover"
                    src={ post.content?.coverimage?.filename && Object.keys(post.content?.coverimage?.filename).length !== 0 ? transformImage(post.content.coverimage.filename , 'filters:quality(20)') : fallbackImage.src}
                    alt=""
                  />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-cyan">
                      {post.category}
                    </p>
                    <div className="block mt-2">
                      <p className="text-xl font-semibold text-gray-900">
                        {post.name}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {post.content.intro}
                      </p>
                    </div>
                  </div>
                  <div className="mt-6 flex items-center">
                    {post?.content.authorImage?.filename && (
                      <div className="flex-shrink-0">
                        <span className="sr-only">{post.author}</span>
                        <img
                          className="h-10 w-10 rounded-full"
                          src={transformImage(post.content.authorImage.filename, '80x0/filters:quality(60)')}
                          alt=""
                        />
                      </div>
                    )}
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900">
                        {post.content.author}
                      </p>
                      <div className="flex space-x-1 text-sm text-gray-900">
                        <time dateTime={post.first_published_at}>
                          {formatDate(post.first_published_at, lang)}
                        </time>
                        <span aria-hidden="true">·</span>
                        <span>
                          {post.content.readingTime} {post.content.readingTime > 1 ? 'minutes' : 'minute'} read
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogOverview;
