import React from 'react';
import { render } from "storyblok-rich-text-react-renderer";


const Content = ({ blok }) => {
  const richtext = blok.text ? blok.text : '';

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">{blok.title}</h2>
          <div className="max-w-3xl mt-5 mx-auto text-xl text-gray-500">
            {render(richtext)}
          </div>        
        </div>
      </div>
    </div>
  );
};

export default Content;
