const FourDayWorkWeek = (props) => (
  <svg
    width="63"
    height="61"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1048 1000"
    {...props}
  >
    <defs>
      <style>
        {
          ".four-day-cls-4{stroke:#212121;stroke-miterlimit:10}.four-day-cls-3{fill:#212121}.four-day-cls-4{fill:#fec111;stroke-width:28px}"
        }
      </style>
    </defs>
    <path
      d="M82.07 865.76a77.35 77.35 0 0 1-4.35-25.89v-532c0-15.26-6.06-86.84 110-86.84 15.52 0 28.29 11.31 28.89 25.64v.25c1.27 29.35 23.46 50.09 55.1 51.08 17.32.54 34.67.41 52 .21 35.85-.42 58.64-21.68 59.15-54.92v-.21c.17-11.72 10.51-21.14 23.19-21.14h225.58c15.5 0 28.25 11.27 28.93 25.58v.53c1.36 27.9 23.57 48.73 53.66 49.87 17 .65 34.09.37 51.14.32 38.46-.12 61.21-21.27 61.37-56.92v-.21c0-10.92 9.64-19.74 21.45-19.74h17.56c36.41 0 99.7 12 99.7 86.5V852.5c0 44.06-40.75 86.51-93.63 86.51H171.35c-47.68 0-75.52-34.61-89.23-73.12Zm801.54-295.28v-118.5c0-2.88-.06-5.76-.26-8.64-1-14.81-9.38-79.58-22.7-88.37-10.9-7.19-590.95 1.92-593.64 2-14.06.57-83.92-5.38-93.79 3.7-11.48 10.56-13.72 76.43-13.7 90.62q.17 178.27.07 356.54a91.28 91.28 0 0 0 .3 9.36 44.14 44.14 0 0 0 14.65 28.87c11.95 11 26.75 14.25 43.3 14.23q151.91-.17 303.8-.05h288.43c8.08 0 16.17.16 24.22-.28 18.57-1 32.61-9 42.22-23.07 6.08-8.93 7.15-18.89 7.14-29.11Z"
      style={{
        fill: "#0297a7",
      }}
    />
    <path
      d="M883.61 540.14V411.88c0-3.12-.06-6.24-.26-9.35-1-16-8.38-28.77-21.7-38.28-10.9-7.79-23.48-9.54-36.69-9.53q-303.39.11-606.78 0c-2.69 0-5.39.09-8.07.21-14.06.62-26 5.64-35.89 15.47-11.48 11.42-14.72 25.35-14.7 40.71q.17 193 .07 385.9c0 3.38 0 6.77.3 10.13a49.25 49.25 0 0 0 14.65 31.25c11.95 11.87 26.75 15.42 43.3 15.4q151.91-.17 303.8-.05h288.44c8.08 0 16.17.18 24.22-.3 18.57-1.1 32.61-9.71 42.22-25 6.08-9.67 7.15-20.45 7.14-31.51ZM82.07 859.73a90 90 0 0 1-4.35-28V255.87c0-16.52-6.06-94 110-94a28.89 28.89 0 0 1 28.89 27.75v.26c1.27 31.78 23.46 54.23 55.1 55.3 17.32.59 34.67.44 52 .23C359.57 245 382.36 222 382.87 186v-.23a23.2 23.2 0 0 1 23.19-22.88h225.57a29 29 0 0 1 28.93 27.69v.57c1.36 30.2 23.57 52.75 53.66 54 17 .7 34.09.4 51.14.34 38.46-.12 61.21-23 61.37-61.6v-.23a21.45 21.45 0 0 1 21.45-21.37h17.56c36.41 0 99.7 13 99.7 93.63v589.46c0 47.68-40.75 93.63-93.63 93.63H171.35c-47.68 0-75.52-37.46-89.23-79.14Zm801.54-319.59V411.88c0-3.12-.06-6.24-.26-9.35-1-16-8.38-28.77-21.7-38.28-10.9-7.79-23.48-9.54-36.69-9.53q-303.39.11-606.78 0c-2.69 0-5.39.09-8.07.21-14.06.62-26 5.64-35.89 15.47-11.48 11.42-14.72 25.35-14.7 40.71q.17 193 .07 385.9c0 3.38 0 6.77.3 10.13a49.25 49.25 0 0 0 14.65 31.25c11.95 11.87 26.75 15.42 43.3 15.4q151.91-.17 303.8-.05h288.44c8.08 0 16.17.18 24.22-.3 18.57-1.1 32.61-9.71 42.22-25 6.08-9.67 7.15-20.45 7.14-31.51Z"
      style={{
        stroke: "#212121",
        strokeMiterlimit: 10,
        fill: "none",
        strokeWidth: 32,
      }}
    />
    <path
      className="four-day-cls-3"
      d="M329.06 27c11.89 7.54 16.05 19.34 15.65 35.93-.81 33.75-.22 67.55-.25 101.33 0 18.08-8.92 29.14-23.47 29.18s-28.91.07-43.37 0c-13.62-.07-22.73-11.38-22.75-28.31-.06-34.07.54-68.16-.26-102.2-.39-16.57 3.67-28.45 15.64-35.92ZM771.8 27c11.43 7.54 15.43 19.34 15.05 35.93-.79 33.75-.22 67.55-.25 101.33 0 18.08-8.58 29.14-22.56 29.18s-27.8.07-41.7 0c-13.1-.07-21.86-11.38-21.88-28.31-.05-34.07.52-68.16-.25-102.2-.37-16.57 3.53-28.45 15-35.92Z"
    />
    <path
      className="four-day-cls-4"
      d="M355.14 496c0 9.24.12 18.49 0 27.74-.25 15.26-11.71 26.76-27 26.89-18.78.17-37.57.14-56.35 0-15.88-.11-27.42-11.48-27.55-27.23q-.22-28.17 0-56.34c.12-15.8 11.59-27.23 27.43-27.34 18.49-.13 37-.1 55.48 0 16.45.08 27.84 11.36 28 27.66.07 9.49-.01 19.03-.01 28.62ZM577.08 495.66v26.88c-.13 16.49-11.32 28-27.6 28.06-18.78.12-37.57.15-56.35 0-15.28-.14-26.81-11.66-27-26.92-.18-19.07-.23-38.15 0-57.22.16-15.2 11.83-26.62 27.16-26.74q28.17-.24 56.35 0c15.82.11 27.22 11.56 27.41 27.37.12 9.5.02 19.04.03 28.57ZM300 661.59c9.25 0 18.49-.09 27.74 0 15.81.2 27.26 11.59 27.37 27.42q.19 28.17 0 56.35c-.13 15.7-11.72 27-27.61 27.15q-27.75.18-55.49 0c-16.35-.1-27.71-11.48-27.8-27.86q-.16-27.3 0-54.61c0-16.93 11.31-28.33 28.11-28.46 9.18-.05 18.43.01 27.68.01ZM577.71 708.06v9.55c0 9 .06 17.92 0 26.87-.13 16.49-11.34 27.95-27.62 28q-27.73.18-55.48 0c-16.37-.09-27.74-11.46-27.84-27.83q-.17-27.74 0-55.48c.1-15.86 11.51-27.47 27.21-27.6q28.17-.24 56.35 0a27.45 27.45 0 0 1 19.56 7.81s7.82 3.17 7.82 38.68ZM795.62 495.66c0 9 .06 17.92 0 26.88-.13 16.49-11.33 28-27.61 28.06-18.78.12-37.57.15-56.35 0-15.28-.14-26.81-11.66-27-26.92-.18-19.07-.23-38.15 0-57.22.16-15.2 11.83-26.62 27.16-26.74q28.17-.24 56.35 0c15.83.11 27.22 11.56 27.42 27.37.12 9.5.03 19.04.03 28.57ZM796.25 708.06v9.55c0 9 .06 17.92 0 26.87-.13 16.49-11.33 27.95-27.62 28q-27.74.18-55.48 0c-16.37-.09-27.73-11.46-27.83-27.83q-.16-27.74 0-55.48c.09-15.86 11.5-27.47 27.21-27.6q28.17-.24 56.34 0a27.48 27.48 0 0 1 19.57 7.81s7.81 3.17 7.81 38.68Z"
    />
  </svg>
);

export default FourDayWorkWeek;
