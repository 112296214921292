import Placeholder from "./Placeholder";
import Page from "./Page";
import PageHeader from "./PageHeader";
import Content from "./Content";
import HeroSplit from "./HeroSplit";
import BigImageTestimonial from "./BigImageTestimonial";
import FeaturesLine from "./FeaturesLine";
import Features from "./Features";
import FeatureTextLeft from "./FeatureTextLeft";
import EndOfPageActions from "./EndOfPageActions";
import Trust from "./Trust";
import CallToAction from "./CallToAction";
import Pricing from "./Pricing";
import Stats from "./Stats";
import PressOverview from "./PressOverview";
import BlogOverview from "./BlogOverview";
import Contact from "./Contact";
import FeatureTextRight from "./FeatureTextRight";
import IntegrationsOverview from "./IntegrationsOverview";
import Integrations from "./Integration";
import Form from "./Form";
import ReferenceOverview from "./ReferenceOverview";
import Dropdown from "./Dropdown";
import CategoryDropdown from "./CategoryDropdown";
import Button from "./Button";
import Hero from "./Hero";
import Hook from "./Hook";
import Testimonial from "./Testimonial";
import References from "./References";

const Components = {
  // text: Text,
  // LandFeatureScreenshot: LandFeatureScreenshot,
  // LandTestimonial: LandTestimonial,
  // LandBlogOverview: LandBlogOverview,
  // PolicyPage: PolicyPage,
  // LandFeatures: LandFeatures,
  // stickybottombanner: StickyBottomBanner,
  // calltoactionbanner: CallToActionBanner,
  // featuretailwind: FeatureScreenshot,
  // ctafeaturescreenshot: CTAFeatureScreenshot,
  // "hubspot-form": HubspotForm,
  // "hubspot-meeting": HubspotMeeting,
  //---------------------------------------------------------------------
  page: Page,
  blog: References,
  pageheader: PageHeader,
  content: Content,
  herosplit: HeroSplit,
  bigimagetestamonial: BigImageTestimonial,
  featuresline: FeaturesLine,
  features: Features,
  featuresleft: FeatureTextLeft,
  featuresright: FeatureTextRight,
  endofpageactions: EndOfPageActions,
  trust: Trust,
  calltoaction: CallToAction,
  pricing: Pricing,
  stats: Stats,
  blogoverview: BlogOverview,
  pressoverview: PressOverview,
  contact: Contact,
  integrationsoverview: IntegrationsOverview,
  integration: Integrations,
  vcustomform: Form,
  references: ReferenceOverview,
  vdropdown: Dropdown,
  categorydropdown: CategoryDropdown,
  vbutton: Button,
  hero: Hero,
  hook: Hook,
  testimonial: Testimonial,
};

const DynamicComponent = ({
  blok,
  locale = undefined,
  translations = undefined,
  settings = undefined,
  crafthuntProfile = undefined,
}) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component];
    return (
      <Component
        blok={blok}
        translations={translations}
        settings={settings}
        locale={locale}
        crafthuntProfile={crafthuntProfile}
      />
    );
  }
  return <Placeholder componentName={blok.component} />;
};

export default DynamicComponent;
