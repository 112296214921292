import { Popover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import { getTranslatedSlug } from "utils/router.helper";

const Dropdown = ({ blok }) => {

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`${
              open ? "text-gray-900" : "text-gray-500"
            } group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan`}
          >
            <span>{blok.triggerText}</span>
            <ChevronDownIcon
              className={`${
                open ? "text-gray-600" : "text-gray-400"
              } ml-2 h-5 w-5 group-hover:text-gray-500`}
              aria-hidden="true"
            />
          </Popover.Button>

          <Popover.Panel
            className={`${
              open ? "opacity-100 translate-y-0" : "opacity-0 translate-y-1"
            } transition-transform ease-in-out duration-200 absolute z-10 -ml-4 mt-3 transform w-screen max-w-xs md:max-w-md lg:max-w-3xl overflow-scroll h-96 md:h-auto rounded-lg shadow-lg ring-1 ring-black ring-opacity-5`}
          >
            <div>
              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                {blok.items.map((item) => (
                  <Link key={item.text} href={item.destination.url}
                  >
                    <a
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-cyan text-white sm:h-12 sm:w-12">
                        <img className="h-6 w-6" aria-hidden="true" src={item.image} alt="" />
                      </div>
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">{item.text}</p>
                        <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                      </div>
                    </a>
                  </Link>
                ))}
              </div>
              <div className="p-5 bg-gray-50 sm:p-8">
                <Link href={blok.bottomLink.url}>
                  <a
                    className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100"
                  >
                    <div className="flex items-center">
                      <div className="text-base font-medium text-gray-900">{blok.bottomText}</div>
                      {blok.bottomChipText && (
                        <span className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-cyan text-white">
                          {blok.bottomChipText}
                        </span>
                      )}
                    </div>
                    <p className="mt-1 text-sm text-gray-500">{blok.bottomDescription}</p>
                  </a>
                </Link>
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default Dropdown;
