import * as React from "react";
export const ArrowLeft = (props) => {
  return (
    <svg
      width={95}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M91.529 8.47c-1.522-1.408-3.577-1.145-5.935-1.174-9.331-.12-18.768.963-28.067 1.583-15.53 1.034-32.082 3.414-47.576.777"
        stroke="#fff"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M13.338 3.202c-2.522 3.59-6.828 3.986-9.867 6.88 2.967 1.208 8.87 3.928 10.767 6.716"
        stroke="#fff"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
