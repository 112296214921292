const DEFAULT_ROOT_PAGE = "";

export const getFullSlug = (fullSlug?: string, prefix?: string) => {
  if (!fullSlug) return "/";
  if (fullSlug.includes("https://")) {
    return fullSlug.replace("https://crafthunt.app", "");
  }
  let cleanUrl = fullSlug.replace(DEFAULT_ROOT_PAGE, "");
  if (cleanUrl.charAt(0) !== "/") {
    cleanUrl = `/${cleanUrl}`;
  }
  if (prefix && !cleanUrl.includes(prefix)) {
    cleanUrl = `/${prefix}${cleanUrl}`;
  }
  return cleanUrl;
};

export const getTranslatedSlug = (item): string => {
  if (item.url?.story?.full_slug) {
    return getFullSlug(item.url.story.full_slug);
  }
  if (item.url?.story?.url) {
    return getFullSlug(item.url.story.url);
  }
  if (item.url?.cached_url) {
    return getFullSlug(item.url.cached_url);
  }
  if (typeof item === 'string') {
    return getFullSlug(item);
  }
  if (typeof item.url === 'string') {
    return getFullSlug(item.url);
  }
  if (typeof item.url?.url === 'string') {
    return getFullSlug(item.url.url);
  }
};
