import React, { useEffect, useState } from 'react';
import CrafthuntLogo from "../assets/crafthunt_logo.svg";
import { isDev } from 'constants/env';
import Storyblok from 'utils/storyblok';
import { useRouter } from 'next/router';
import LanguageSelector from '@molteo-ui/LanguageSelector';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa';
import Link from 'next/link';
import { getTranslatedSlug } from 'utils/router.helper';
import { EnvelopeIcon, PhoneIcon, PlayIcon } from '@heroicons/react/20/solid';
// Import other icons and components as needed

const Footer = ({ translatedSlugs }) => {
  const router = useRouter();
  const lang = router.locale
  const [story, setStory] = useState({
    content: {
      language: "",
      de: "",
      en: "",
      title: "",
      description: "",
      footer_navi: [],
      footer_social: [],
      videoBtn: "",
      emailBtn: "",
      phoneBtn: "",
      videoLink: {
        url: "",
      },
      copyright: ""
    },
  },
);

  useEffect(() => {
    getStory();
  }, [lang]);

  const getStory = async () => {
    const sbParam = {
      version: isDev() ? "draft" : "published",
      language: lang,

    }
    try{
      const response = await Storyblok.get(
        "cdn/stories/settings",
        sbParam
      )
      setStory(response.data.story)
    } catch (error) {
      console.log(error);
    }
  }

  const currentYear = new Date().getFullYear();

  const getIcon = (icon: string) => {
    switch (icon) {
      case "facebook":
      case "facebook-square":
        return <FaFacebook className="h-6 w-6" />;
      case "instagram":
        return <FaInstagram className="h-6 w-6" />;
      case "linkedin":
        return <FaLinkedin className="h-6 w-6" />;
      case "twitter":
        return <FaTwitter className="h-6 w-6" />;
      case "youtube":
        return <FaYoutube className="h-6 w-6" />;
      case "crafthunt":
        return <CrafthuntLogo className="h-5 w-5" />;
      default:
        return null;
    }
  };

  return (
    <footer className="bg-gray-800" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-12 lg:pt-16 pb-24 sm:pb-28 2xl:pb-16">
        <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-4">
            {story.content.footer_navi &&
              story.content.footer_navi.map((item, index) => (
                <div key={item._uid} className={`${index === 1 ? 'mt-12 md:mt-0' : ''}  md:grid md:grid-cols-2 md:gap-8`}>
                  <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    {item.title}
                  </h3>
                  <ul className="mt-4 space-y-4 ">
                    {item.items.map((subItem) => (
                      <li key={subItem._uid}>
                        <Link href={getTranslatedSlug(subItem.destination)} >
                          <a className="text-base text-gray-300 hover:text-white">
                            {subItem.text}
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                  </div>
                </div>
              ))}
          </div>
          <div className="mt-12 xl:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              {story.content.language}
            </h3>
            <div className="z-10 md:flex md:items-center md:space-x-6 hidden md:show">
                <LanguageSelector translatedSlugs={translatedSlugs} />
            </div>
          </div>
        </div>
        <div className="border-t border-gray-700 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              {story.content.title}
            </h3>
            <p className="mt-2 text-base text-gray-300">
              {story.content.description}
            </p>
          </div>
          <div className="mt-4 sm:max-w-md flex gap-1 lg:mt-0">
            {story.content.videoLink && story.content.videoLink.url && (
              <Link href={getTranslatedSlug(story.content.videoLink)} >
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="
                  sm:flex-1
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  shadow-sm
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-cyan
                  hover:bg-cyan-dark
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-cyan
                "
              >
                <PlayIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                {story.content.videoBtn}
              </a>
              </Link>
            )}
            <a
              href="mailto:team@molteo.de"
              className="
                sm:flex-1
                inline-flex
                items-center
                px-4
                py-2
                border border-transparent
                shadow-sm
                text-sm
                font-medium
                rounded-md
                text-white
                bg-cyan
                hover:bg-cyan-dark
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-cyan
           "
            >
              <EnvelopeIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              {story.content.emailBtn}
            </a>
            <a
              href="tel:004946219853005"
              className="
                sm:flex-1
                inline-flex
                items-center
                px-4
                py-2
                border border-transparent
                shadow-sm
                text-sm
                font-medium
                rounded-md
                text-white
                bg-cyan
                hover:bg-cyan-dark
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-cyan
              "
            >
              <PhoneIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              {story.content.phoneBtn}
            </a>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {story.content.footer_social.map((item) => (
              <Link href={getTranslatedSlug(item.link)} key={item._uid}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-gray-300"
              >
                <span className="sr-only">{item.name}</span>
                <span>{getIcon(item.icon)}</span>
              </a>
              </Link>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            © {currentYear} {story.content.copyright}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
