import React, { useState } from 'react'
import { transformImage } from 'utils/image.helper';
import Link from 'next/link';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';


const Pricing = ({ blok }) => {

  const [isMonthly, setIsMonthly] = useState(false)
  return (
    <>
      <div className="bg-gray-50">
        {/* <!-- Pricing section --> */}
        <div>
          <div className="relative bg-cyan">
            {/* <!-- Overlapping background --> */}
            <div
              aria-hidden="true"
              className="hidden absolute bg-gray-50 w-full h-6 bottom-0 lg:block"
            />

            <div
              className="
                relative
                max-w-2xl
                mx-auto
                pt-16
                px-4
                text-center
                sm:pt-32
                sm:px-6
                lg:max-w-7xl
                lg:px-8
              "
            >
              <h1
                className="
              text-4xl
              font-extrabold
              tracking-tight
              text-white
              sm:text-6xl
            "
              >
                <span className="block lg:inline">{blok.title}</span>
              </h1>
              <p className="mt-4 text-xl text-cyan-light">{blok.subtitle}</p>
            </div>

            <h2 className="sr-only">Plans</h2>

            {/* <!-- Toggle --> */}
            <div className="relative mt-12 flex justify-center sm:mt-16">
              <div className="bg-cyan-dark p-0.5 rounded-lg flex">
                <button
                  onClick={() => {
                    setIsMonthly(true)
                  }}
                  type="button"
                  className={`relative ${isMonthly ? 'bg-white' : 'text-cyan-light'
                    } py-2 px-6 border-cyan-dark rounded-md shadow-sm text-sm font-medium  whitespace-nowrap hover:bg-cyan focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cyan-dark focus:ring-white focus:z-10`}
                >
                  {blok.monthText}
                </button>
                <button
                  onClick={() => {
                    setIsMonthly(false)
                  }}
                  type="button"
                  className={`ml-0.5 relative ${!isMonthly ? 'bg-white' : 'text-cyan-light'
                    } py-2 px-6 border border-transparent rounded-md text-sm font-medium whitespace-nowrap hover:bg-cyan focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cyan focus:ring-white focus:z-10`}
                >
                  {blok.yearText}
                </button>
              </div>
            </div>
            {/* <!-- Cards --> */}
            <div
              className="
                relative
                mt-8
                max-w-2xl
                mx-auto
                px-4
                pb-8
                sm:mt-12
                sm:px-6
                lg:max-w-7xl
                lg:px-8
                lg:pb-0
              "
            >
              {/* <!-- Decorative background --> */}
              <div
                aria-hidden="true"
                className="
                  hidden
                  absolute
                  top-4
                  bottom-6
                  left-8
                  right-8
                  inset-0
                  bg-cyan-dark
                  rounded-tl-lg rounded-tr-lg
                  lg:block
                "
              />

              <div className="relative space-y-6 lg:space-y-0 lg:grid lg:grid-cols-3">
                {blok.modules.map((plan) => (

                  <div
                    key={plan._uid}
                    className={`
                      ${plan.featured
                        ? 'bg-white ring-2 ring-cyan-dark shadow-md'
                        : 'bg-cyan-dark lg:bg-transparent'}
                      pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12,
                    `}
                  >
                    <div>
                      <h3
                        className={`${plan.featured ? 'text-cyan' : 'text-white'} text-sm font-semibold uppercase tracking-wide`}
                      >
                        {plan.name}
                      </h3>
                      <div
                        className="
                          flex flex-col
                          items-start
                          sm:flex-row
                          sm:items-center
                          sm:justify-between
                          lg:flex-col
                          lg:items-start
                        "
                      >
                        <div className="mt-3 flex items-center">
                          <p className={`${plan.featured ? 'text-cyan' : 'text-white'} text-4xl font-extrabold tracking-tight`}>
                            {isMonthly ? plan.monthlyPrice : plan.annualPrice}€
                          </p>
                          <div className="ml-4">
                            <p className={`${plan.featured ? 'text-gray-700' : 'text-white'} text-sm`}>
                              {plan.unit}
                            </p>
                            <p
                              className={`${plan.featured ? 'text-gray-500' : 'text-cyan-light'} text-sm`}
                            >
                              {
                                isMonthly
                                  ? plan.monthlyFrequencyLabel
                                  : plan.annualFrequencyLabel
                              }
                            </p>
                          </div>
                        </div>
                        <Link 
                          href={
                              plan && plan.buttonLink && plan.buttonLink.url
                                ? plan.buttonLink.url
                                : '#'
                            } >
                          <a
                            className={`${plan.featured ? 'bg-amber text-white hover:bg-amber-dark': 'bg-white text-cyan hover:bg-cyan-light'} mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full`}
                          >
                            {plan.buttonText}
                          </a>
                        </Link>
                      </div>
                    </div>
                    <h4 className="sr-only">Features</h4>
                    <ul
                      className={`
                        ${plan.featured
                            ? 'border-gray-200 divide-gray-200'
                            : 'border-cyan divide-cyan divide-opacity-75'} mt-7 border-t divide-y lg:border-t-0
                      `}
                    >
                      {plan.advantages.map((mainFeature) => {
                        return ( 
                          <li
                            key={mainFeature._uid}
                            className="py-3 flex items-center"
                          >
                            <CheckIcon
                              v-if="mainFeature.value"
                              className={`
                                ${plan.featured ? 'text-cyan' : 'text-cyan-light'} w-5 h-5 flex-shrink-0
                              `}
                              aria-hidden="true"
                            />
                            <span
                              className={`
                                ${plan.featured ? 'text-gray-600' : 'text-white'} ml-4 text-sm font-medium
                              `}
                            >
                              {mainFeature.value}
                            </span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/*<!-- Feature comparison -->*/}
          <section aria-labelledby="mobile-comparison-heading" className="lg:hidden">
            <h2 id="mobile-comparison-heading" className="sr-only">
              Feature comparison
            </h2>

            <div className="mt-16 max-w-2xl mx-auto px-4 space-y-16 sm:px-6">
              {blok.modules.map((plan, planIndex) => (
                <div
                  key={plan._uid}
                  className="border-t border-gray-200"
                >
                  <div
                    className={`
                      ${plan.featured ? 'border-cyan' : 'border-transparent'} -mt-px pt-6 border-t-2 sm:w-1/2
                    `}
                  >
                    <h3
                      className={`
                        ${plan.featured ? 'text-cyan' : 'text-gray-900'} text-sm font-bold
                      `}
                    >
                      {plan.name}
                    </h3>
                    <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
                  </div>
                  <h4 className="mt-10 text-sm font-bold text-gray-900">
                    {blok.compareIntroText}
                  </h4>

                  <div className="mt-6 relative">
                    {/* <!-- Fake card background --> */}
                    <div
                      aria-hidden="true"
                      className="hidden absolute inset-0 pointer-events-none sm:block"
                    >
                      <div
                        className={`
                          ${plan.featured ? 'shadow-md' : 'shadow'} absolute right-0 w-1/2 h-full bg-white rounded-lg
                        `}
                      />
                    </div>

                    <div
                      className={`
                        ${plan.featured
                          ? 'ring-2 ring-cyan shadow-md'
                          : 'ring-1 ring-black ring-opacity-5 shadow'} relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none
                      `}
                    >
                      <dl className="divide-y divide-gray-200">

                        {blok.features.map((feature) => {
                          return (
                          <div
                            key={feature._uid}
                            className="
                              py-3
                              flex
                              items-center
                              justify-between
                              sm:grid sm:grid-cols-2
                            "
                          >
                            <dt className="pr-4 text-sm font-medium text-gray-600">
                              {feature.title}
                            </dt>
                            <dd
                              className="
                                flex
                                items-center
                                justify-end
                                sm:px-4
                                sm:justify-center
                              "
                            >
                              {feature.tiers[planIndex].valueType === 'text' && <span
                                className={`
                                  ${feature.tiers[planIndex].featured
                                    ? 'text-cyan'
                                    : 'text-gray-900'} text-sm font-medium
                                `}
                              >
                                {feature.tiers[planIndex].value}
                              </span>}
                              {feature.tiers[planIndex].valueType !== 'text' &&
                                <div>
                                  {feature.tiers[planIndex].checkValue === true ? <CheckIcon
                                    className="mx-auto h-5 w-5 text-cyan"
                                    aria-hidden="true"
                                  /> : <XMarkIcon
                                    className="mx-auto h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />}

                                  <span className="sr-only">
                                    {
                                      feature.tiers[planIndex].checkValue === true
                                        ? "Yes"
                                        : "No"
                                    }
                                  </span>
                                </div>}
                            </dd>
                          </div>
                        )})}

                      </dl>
                    </div>

                    {/* <!-- Fake card border --> */}
                    <div
                      aria-hidden="true"
                      className="hidden absolute inset-0 pointer-events-none sm:block"
                    >
                      <div
                        className={`
                          ${plan.featured
                            ? 'ring-2 ring-cyan'
                            : 'ring-1 ring-black ring-opacity-5'} absolute right-0 w-1/2 h-full rounded-lg
                        `}
                      />
                    </div>
                  </div>

                  <h4 className="mt-10 text-sm font-bold text-gray-900">
                    {blok.perksIntroText}
                  </h4>

                  <div className="mt-6 relative">
                    {/* <!-- Fake card background --> */}
                    <div
                      aria-hidden="true"
                      className="hidden absolute inset-0 pointer-events-none sm:block"
                    >
                      <div
                        className={`
                          ${plan.featured ? 'shadow-md' : 'shadow'} absolute right-0 w-1/2 h-full bg-white rounded-lg
                        `}
                      />
                    </div>

                    <div
                      className={`
                        ${plan.featured
                          ? 'ring-2 ring-cyan shadow-md'
                          : 'ring-1 ring-black ring-opacity-5 shadow'} relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none
                      `}
                    >
                      <dl className="divide-y divide-gray-200">
                        {blok.perks.map((perk) => {
                          return (
                            <div
                              key={perk._uid}
                              className="py-3 flex justify-between sm:grid sm:grid-cols-2"
                            >
                              <dt className="pr-4 text-sm font-medium text-gray-600">
                                {perk.title}
                              </dt>
                              <dd
                                className="
                                  flex
                                  items-center
                                  justify-end
                                  sm:px-4
                                  sm:justify-center
                                "
                              >
                                {perk.tiers[planIndex].valueType === 'text' ? <span
                                  className={`
                                    ${perk.tiers[planIndex].featured
                                      ? 'text-cyan'
                                      : 'text-gray-900'} text-sm font-medium
                                  `}
                                >
                                  {perk.tiers[planIndex].value}
                                </span> : <div>
                                  {perk.tiers[planIndex].checkValue === true ? <CheckIcon
                                    className="mx-auto h-5 w-5 text-cyan"
                                    aria-hidden="true"
                                  /> : <XMarkIcon
                                    className="mx-auto h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />}


                                  <span className="sr-only">
                                    {
                                      perk.tiers[planIndex].checkValue === true
                                        ? "Yes"
                                        : "No"
                                    }
                                  </span>
                                </div>}

                              </dd>
                            </div>
                          )

                        })}
                      </dl>
                    </div>

                    {/* <!-- Fake card border --> */}
                    <div
                      aria-hidden="true"
                      className="hidden absolute inset-0 pointer-events-none sm:block"
                    >
                      <div
                        className={`
                          ${plan.featured
                            ? 'ring-2 ring-cyan'
                            : 'ring-1 ring-black ring-opacity-5'} absolute right-0 w-1/2 h-full rounded-lg
                        `}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section aria-labelledby="comparison-heading" className="hidden lg:block">
            <h2 id="comparison-heading" className="sr-only">Feature comparison</h2>

            <div className="mt-24 max-w-7xl mx-auto px-8">
              <div className="w-full border-t border-gray-200 flex items-stretch">
                <div className="-mt-px w-1/4 py-6 pr-4 flex items-end">
                  <h3 className="mt-auto text-sm font-bold text-gray-900">
                    {blok.compareIntroText}
                  </h3>
                </div>
                {blok.modules.map((plan, index) => (
                  <div
                    key={plan._uid}
                    aria-hidden="true"
                    className={`
                      ${index === blok.modules.length - 1 ? '' : 'pr-4'} -mt-px pl-4 w-1/4
                    `}
                  >
                    <div
                      className={`
                        ${plan.featured ? 'border-cyan' : 'border-transparent'} py-6 border-t-2
                      `}
                    >
                      <p
                        className={`
                          ${plan.featured ? 'text-cyan' : 'text-gray-900'} text-sm font-bold
                        `}
                      >
                        {plan.name}
                      </p>
                      <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="relative">
                {/* <!-- Fake card backgrounds --> */}
                <div
                  className="absolute inset-0 flex items-stretch pointer-events-none"
                  aria-hidden="true"
                >
                  <div className="w-1/4 pr-4" />
                  <div className="w-1/4 px-4">
                    <div className="w-full h-full bg-white rounded-lg shadow" />
                  </div>
                  <div className="w-1/4 px-4">
                    <div className="w-full h-full bg-white rounded-lg shadow-md" />
                  </div>
                  <div className="w-1/4 pl-4">
                    <div className="w-full h-full bg-white rounded-lg shadow" />
                  </div>
                </div>

                <table className="relative w-full">
                  <caption className="sr-only">
                    {
                      blok.compareIntroText
                    }
                  </caption>
                  <tbody className="divide-y divide-gray-100">
                    {blok.features.map((feature, featureIndex) => (
                      <tr
                        key={feature._uid}
                      >
                        <th
                          scope="row"
                          className="
                            w-1/4
                            py-3
                            pr-4
                            text-left text-sm
                            font-medium
                            text-gray-600
                          "
                        >
                          {feature.title}
                        </th>
                        {feature.tiers.map((tier, index) => (
                          <td
                            key={tier._uid}
                            className={`
                              ${index === feature.tiers.length - 1 ? 'pl-4' : 'px-4'} relative w-1/4 py-0 text-center
                            `}
                          >
                            <span className="relative w-full h-full py-3">
                              {tier.valueType === 'text' ? <span
                                className={`
                                  ${tier.featured ? 'text-cyan' : 'text-gray-900'} text-sm font-medium
                                `}
                              >
                                {tier.value}
                              </span> : <div>
                                {tier.checkValue === true ? <CheckIcon
                                  className="mx-auto h-5 w-5 text-cyan"
                                  aria-hidden="true"
                                /> :
                                  <XMarkIcon
                                    className="mx-auto h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />}
                                <span className="sr-only">{
                                  tier.checkValue === true ? "Yes" : "No"
                                }</span>
                              </div>}

                            </span>
                          </td>
                        ))}
                      </tr>

                    ))}
                  </tbody>
                </table>

                {/* <!-- Fake card borders --> */}
                <div
                  className="absolute inset-0 flex items-stretch pointer-events-none"
                  aria-hidden="true"
                >
                  <div className="w-1/4 pr-4" />
                  <div className="w-1/4 px-4">
                    <div
                      className="
                        w-full
                        h-full
                        rounded-lg
                        ring-1 ring-black ring-opacity-5
                      "
                    />
                  </div>
                  <div className="w-1/4 px-4">
                    <div
                      className="
                        w-full
                        h-full
                        rounded-lg
                        ring-2 ring-cyan ring-opacity-100
                      "
                    />
                  </div>
                  <div className="w-1/4 pl-4">
                    <div
                      className="
                        w-full
                        h-full
                        rounded-lg
                        ring-1 ring-black ring-opacity-5
                      "
                    />
                  </div>
                </div>
              </div>

              <h3 className="mt-10 text-sm font-bold text-gray-900">
                {blok.perksIntroText}
              </h3>

              <div className="mt-6 relative">
                {/* <!-- Fake card backgrounds --> */}
                <div
                  className="absolute inset-0 flex items-stretch pointer-events-none"
                  aria-hidden="true"
                >
                  <div className="w-1/4 pr-4" />
                  <div className="w-1/4 px-4">
                    <div className="w-full h-full bg-white rounded-lg shadow" />
                  </div>
                  <div className="w-1/4 px-4">
                    <div className="w-full h-full bg-white rounded-lg shadow-md" />
                  </div>
                  <div className="w-1/4 pl-4">
                    <div className="w-full h-full bg-white rounded-lg shadow" />
                  </div>
                </div>

                <table className="relative w-full">
                  <caption className="sr-only">
                    Perk comparison
                  </caption>
                  <tbody className="divide-y divide-gray-100">
                    {blok.perks.map((perk) => (
                      <tr key={perk._uid} >
                        <th
                          scope="row"
                          className="
                            w-1/4
                            py-3
                            pr-4
                            text-left text-sm
                            font-medium
                            text-gray-600
                          "
                        >
                          {perk.title}
                        </th>
                        {perk.tiers.map((tier, index) => (
                          <td
                            key={tier.title}
                            className={`
                              ${index === perk.tiers.length - 1 ? 'pl-4' : 'px-4'} relative w-1/4 py-0 text-center
                            `}
                          >
                            <span className="relative w-full h-full py-3">
                              {tier.valueType === 'text' ? <span
                                className={`
                                  ${tier.featured ? 'text-cyan' : 'text-gray-900'} text-sm font-medium
                                `}
                              >
                                {tier.value}
                              </span> : <div>
                                {tier.checkValue === true ? <CheckIcon
                                  className="mx-auto h-5 w-5 text-cyan"
                                  aria-hidden="true"
                                /> :
                                  <XMarkIcon
                                    className="mx-auto h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />}
                                <span className="sr-only">{
                                  tier.checkValue === true ? "Yes" : "No"
                                }</span>
                              </div>}

                            </span>
                          </td>

                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* <!-- Fake card borders --> */}
                <div
                  className="absolute inset-0 flex items-stretch pointer-events-none"
                  aria-hidden="true"
                >
                  <div className="w-1/4 pr-4" />
                  <div className="w-1/4 px-4">
                    <div
                      className="
                        w-full
                        h-full
                        rounded-lg
                        ring-1 ring-black ring-opacity-5
                      "
                    />
                  </div>
                  <div className="w-1/4 px-4">
                    <div
                      className="
                        w-full
                        h-full
                        rounded-lg
                        ring-2 ring-cyan ring-opacity-100
                      "
                    />
                  </div>
                  <div className="w-1/4 pl-4">
                    <div
                      className="
                        w-full
                        h-full
                        rounded-lg
                        ring-1 ring-black ring-opacity-5
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Logo Cloud */}
      {blok.trustLogos.map((trust) => (
        <section
          key={trust.title}
          aria-labelledby="customer-heading"
          className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:max-w-7xl lg:py-24 lg:px-8"
        >
          <h2 id="customer-heading" className="sr-only">
            {trust.title}
          </h2>
          <div className="mt-10 grid grid-cols-2 gap-10 md:grid-cols-6 lg:grid-cols-6">
            {trust.logos.map((logo) => (
              <div
                key={logo._uid}
                className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
              >
                {logo?.image?.filename && (
                  <img
                    className="h-24 w-full object-contain"
                    src={transformImage(logo.image.filename, '350x0/filters:quality(60)')}
                    alt="Customer Logo"
                  />
                )}
              </div>
            ))}
          </div>
        </section>
      ))}

        {/*<!-- Testimonial -->*/}
        <div className="relative">
          {/* <!-- Decorative background --> */}
          <div
            aria-hidden="true"
            className="absolute inset-x-0 h-1/2 bg-gradient-to-b from-white to-gray-50"
          />

          <div
            className="
              relative
              max-w-2xl
              mx-auto
              py-16
              px-4
              sm:px-6
              lg:max-w-7xl
              lg:px-8
            "
          >
            <div
              className="
                relative
                py-24
                px-8
                bg-cyan
                rounded-xl
                shadow-2xl
                overflow-hidden
                lg:px-16
                lg:grid lg:grid-cols-2
                lg:gap-x-8
              "
            >
              <div
                className="
                  absolute
                  inset-0
                  opacity-50
                  filter
                  saturate-0
                  mix-blend-multiply
                "
              >
                {blok.backgroundimage?.filename && <img
                  src={
                    transformImage(
                      blok.backgroundimage.filename,
                      '1600x0/filters:quality(60)'
                    )}

                  alt=""
                  className="w-full h-full object-cover"
                />}
              </div>
              <div className="relative lg:col-span-1">
                {blok.logo?.filename && <img
                  className="h-12 w-auto"
                  src={
                    transformImage(
                      blok.logo.filename,
                      '160x0/filters:quality(60)'
                    )
                  }
                  alt=""
                />}
                <blockquote className="mt-6 text-white">
                  <p className="text-xl font-medium sm:text-2xl">{blok.quote}</p>
                  <footer className="mt-6">
                    <p className="flex flex-col font-medium">
                      <span>{blok.author}</span>
                      <span>{blok.authorTitle}</span>
                    </p>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>

        {/*<!-- FAQs -->*/}
        <section aria-labelledby="faq-heading" className="bg-gray-900">
          <div
            className="
              max-w-2xl
              mx-auto
              py-16
              px-4
              sm:py-24
              sm:px-6
              lg:max-w-7xl
              lg:px-8
            "
          >
            <div className="max-w-2xl lg:mx-auto lg:text-center">
              <h2
                id="faq-heading"
                className="
                  text-3xl
                  font-extrabold
                  tracking-tight
                  text-white
                  sm:text-4xl
                "
              >
                {blok.faqTitle}
              </h2>
              <p className="mt-4 text-gray-400">{blok.faqDescription}</p>
            </div>
            <div className="mt-20">
              <dl
                className="
                  space-y-10
                  lg:space-y-0
                  lg:grid lg:grid-cols-2
                  lg:gap-x-8 lg:gap-y-10
                "
              >
                {blok.questions.map((faq) => (
                  <div key={faq._uid}>
                    <dt className="font-semibold text-white">
                      {faq.value}
                    </dt>
                    <dd className="mt-3 text-gray-400">
                      {faq.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Pricing