export const SpezialisedTasks = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-40 150 1048 1000"
      width="63"
      height="61"
      {...props}
    >
      <defs>
        <style>
          {
            ".spezialised-tasks-cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:22px}.spezialised-tasks-cls-2{fill:#f9a01f}"
          }
        </style>
      </defs>
      <path
        className="spezialised-tasks-cls-1"
        d="M715.17 533.24s17-56.24 119-56.24c36.74 0 59.61-2 85 5.66 48.7 14.66 65.67 38.79 62.33 85-1.51 20.85-7.08 72.94-11.33 79.33-17 25.56-39.67-11-39.67-26.3s-215.33-87.45-215.33-87.45Z"
      />
      <path
        className="spezialised-tasks-cls-2"
        d="M89 834h388.17v153H89zM548.26 834h388.17v153H548.26z"
      />
      <path
        className="spezialised-tasks-cls-2"
        d="M309.45 684h388.17v153H309.45zM545.35 526h388.17v153H545.35zM88.45 526h388.17v153H88.45z"
      />
      <path
        className="spezialised-tasks-cls-2"
        d="M310.26 376h388.17v153H310.26z"
      />
      <path
        className="spezialised-tasks-cls-1"
        d="M239.17 376h459v153h-459zM239.17 681h459v153h-459z"
      />
      <path
        className="spezialised-tasks-cls-1"
        d="M18.17 528.33h459v153h-459zM477.17 528.33h459v153h-459zM18.17 834h459v153h-459zM477.17 834h459v153h-459z"
      />
    </svg>
  );
};
