import Link from 'next/link';
import React from 'react';
import { transformImage } from 'utils/image.helper';
import { getTranslatedSlug } from 'utils/router.helper';

const FeatureTextRight = ({ blok }) => {
  return (
    <>
      {blok.pattern !== '' && (
        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width="404"
          height="550"
          fill="none"
          viewBox="0 0 404 550"
          aria-hidden="true"
        >
          <pattern
            id={`64e643ad-2176-4f86-b3d7-f2c5da3b6a6d+${blok._uid}`}
            x="0"
            y="0"
            width={blok.pattern === 'grid' ? '100' : '42'}
            height={blok.pattern === 'grid' ? '100' : '44'}
            patternUnits="userSpaceOnUse"
          >
            {/* Grid SVG */}
            {blok.pattern === 'grid' && (
              <svg
                className="text-gray-200"
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 100 100"
              >
                <g fillRule="evenodd">
                  <g fill="currentColor">
                    <path opacity=".5" d={blok.d} />
                    <path d="M6 5V0H5v5H0v1h5v94h1V6h94V5H6z" />
                  </g>
                </g>
              </svg>
            )}
            {/* Bricks SVG */}
            {blok.pattern === 'bricks' && (
              <svg
                className="text-gray-200"
                width="42"
                height="44"
                viewBox="0 0 42 44"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Page-1" fill="none" fillRule="evenodd">
                  <g id="brick-wall" fill="currentColor">
                    <path d="M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z" />
                  </g>
                </g>
              </svg>
            )}
          </pattern>
          <rect
            width="404"
            height="784"
            fill={`url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d+${blok._uid})`}
          />
        </svg>
      )}

      <div className="relative mt-12 sm:mt-16 lg:mt-24">
        {blok.pattern !== '' && (
          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
            width="404"
            height="550"
            fill="none"
            viewBox="0 0 404 550"
            aria-hidden="true"
          >
            <pattern
              id={`64e643ad-2176-4f86-b3d7-f2c5da3b6a6d+${blok._uid}`}
              x="0"
              y="0"
              width={blok.pattern === 'grid' ? '100' : '42'}
              height={blok.pattern === 'grid' ? '100' : '44'}
              patternUnits="userSpaceOnUse"
            >
              {/* Grid SVG */}
              {blok.pattern === 'grid' && (
                <svg
                  className="text-gray-200"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                >
                  <g fillRule="evenodd">
                    <g fill="currentColor">
                      <path opacity=".5" d={blok.d} />
                      <path d="M6 5V0H5v5H0v1h5v94h1V6h94V5H6z" />
                    </g>
                  </g>
                </svg>
              )}
              {/* Bricks SVG */}
              {blok.pattern === 'bricks' && (
                <svg
                  className="text-gray-200"
                  width="42"
                  height="44"
                  viewBox="0 0 42 44"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Page-1" fill="none" fillRule="evenodd">
                    <g id="brick-wall" fill="currentColor">
                      <path d="M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z" />
                    </g>
                  </g>
                </svg>
              )}
            </pattern>
            <rect
              width="404"
              height="784"
              fill={`url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d+${blok._uid})`}
            />
          </svg>
        )}

        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="lg:col-start-2">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              {blok.title}
            </h3>
            <p className="mt-3 text-lg text-gray-500">{blok.subtitle}</p>

            <dl className="mt-10 space-y-10">
              {blok.advantages.map((item) => (
                <div key={item._uid} className="relative">
                  <dt>
                    <div className="
                      absolute
                      flex
                      items-center
                      justify-center
                      h-12
                      w-12
                      rounded-md
                      bg-cyan
                      text-white
                    "
                    >
                      <img
                        className="h-8 w-8 text-white"
                        aria-hidden="true"
                        src={item.icon.filename}
                        alt={item.icon.alt}
                      />
                    </div>
                    <p className="ml-16 text-lg font-medium text-gray-900">{item.title}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
            <img
              src={transformImage(blok.image.filename, '1000x0/filters:quality(80)')}
              alt={blok.image.alt}
              width={blok.imageSize ? blok.imageSize : '490'}              
              height={500}
              className="relative mx-auto shadow-lg"
            />
            {blok.contenttype === 'video' &&
            <Link href={getTranslatedSlug(blok.videolink)}>
              <a>
                <img
                  className="relative mx-auto shadow-lg"
                  height="590"
                  src={blok.videoplaceholder.url}
                />
              </a>
            </Link>
            }
          </div>
        </div>
      </div>

    </>);
};

export default FeatureTextRight;
