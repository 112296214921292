import Script from "next/script";
import { ENV, isProd } from "constants/env";

export const CrispChat = () => {
  if (!isProd()) return null;
  return (
    <Script
      id="crisp-chat"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `window.$crisp=[];window.CRISP_WEBSITE_ID="${ENV.CRISP_KEY}";(function(){ d=document;s=d.createElement("script"); s.src="https://client.crisp.chat/l.js"; s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();window.$crisp.push(["safe", true]);`,
      }}
    />
  );
};
