const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="48 0 1048 1000"
    width="63"
    height="61"
    {...props}
  >
    <defs>
      <style>
        {
          ".officials-cls-3{stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:31.01px;fill:none}"
        }
      </style>
    </defs>
    <path
      d="M786 96.1h94.44c8.92 0 16.14 8.27 16.14 18.49v800.46c0 10.21-7.22 18.49-16.14 18.49H366.09c-8.92 0-16.15-8.28-16.15-18.49V114.59c0-10.22 7.23-18.49 16.15-18.49H673"
      style={{
        fill: "#f9a01f",
      }}
    />
    <path
      style={{
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "31.01px",
        fill: "#0297a7",
      }}
      d="M698.45 62.46h73.96v303.77l-65.94-63.4h-2.35l-61.14 63.4V62.46h73.96"
    />
    <path
      className="officials-cls-3"
      d="M772.4 96.1h108.17a18.49 18.49 0 0 1 18.49 18.49v800.46a18.49 18.49 0 0 1-18.49 18.49H291.43a18.49 18.49 0 0 1-18.49-18.49V114.59a18.49 18.49 0 0 1 18.49-18.49H643M402.61 469.24h369.8M402.61 561.69h369.8M402.61 654.14h369.8M402.61 746.59h369.8"
    />
  </svg>
);

export default SvgComponent;
