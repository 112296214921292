import { format, isAfter, isBefore, formatDistance } from "date-fns";
import { de, pl, ro, es, it, el, fr, cs, da, ptBR } from "date-fns/locale";

export const getDateLocale = (
  locale:
    | "en"
    | "de"
    | "pl"
    | "ro"
    | "es"
    | "it"
    | "el"
    | "fr"
    | "cs"
    | "da"
    | "pt-br"
    | string
) => {
  switch (locale) {
    case "de":
      return { locale: de };
    case "pl":
      return { locale: pl };
    case "ro":
      return { locale: ro };
    case "es":
      return { locale: es };
    case "it":
      return { locale: it };
    case "el":
      return { locale: el };
    case "fr":
      return { locale: fr };
    case "cs":
      return { locale: cs };
    case "da":
      return { locale: da };
    case "pt-br":
      return { locale: ptBR };
    default:
      return undefined;
  }
};

export enum DateFormats {
  D_M_Y = "do MMM yyyy",
  M_Y = "MMM yyyy",
}

export const formatDate = (
  dateStr: string | null | undefined,
  locale: string,
  dateFormat = DateFormats.D_M_Y
) => {
  if (!dateStr) return dateStr;
  return format(new Date(dateStr), dateFormat, getDateLocale(locale));
};

export const isBetween = (
  startDate: string,
  endDate: string,
  dateToCompare: string
) => {
  const isDateAfter = isAfter(new Date(dateToCompare), new Date(startDate));
  const isDateBefore = isBefore(
    new Date(dateToCompare),
    endDate ? new Date(endDate) : new Date()
  );
  return isDateAfter && isDateBefore;
};

export const duration = (
  startDate: string,
  endDate: string,
  locale: string
) => {
  return formatDistance(new Date(startDate), new Date(endDate), {
    ...getDateLocale(locale),
    addSuffix: true,
  });
};
