const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1048 1000"
    width="63"
    height="61"
    {...props}
  >
    <defs>
      <style>
        {
          ".health-cls-2{fill:none;stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:37.05px}"
        }
      </style>
    </defs>
    <path
      d="M568.5 716.61a22.83 22.83 0 0 1-8.23-17.5l2-434.84c0-34.25-6-62.27-40.27-62.27h-4c-34.25 0-40.27 28-40.27 62.27v431.84a22.56 22.56 0 0 1-8 17.34c-24 19.95-48.36 49.9-48.69 83.45-.6 60.61 35.59 110.67 96.21 111.1 61.1.43 98.79-40 98.79-101-.04-33.91-23.41-70.22-47.54-90.39Z"
      style={{
        fill: "#00bcd4",
      }}
    />
    <path
      className="health-cls-2"
      d="M608.39 701.84a29.27 29.27 0 0 1-10.56-22.44V125.78A80.07 80.07 0 0 0 518 46h0a80.07 80.07 0 0 0-79.83 79.83V679.4a28.9 28.9 0 0 1-10.31 22.23 140.66 140.66 0 0 0-50.85 107c-.77 77.7 62.27 141.88 140 142.42a141 141 0 0 0 91.41-249.2ZM440.5 241.5h75M440.5 363.5h75M440.5 485.5h75M440.5 607.5h75"
    />
  </svg>
);

export default SvgComponent;
