const DigitalApp = (props) => (
  <svg
    width="63"
    height="61"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1048 1000"
    {...props}
  >
    <defs>
      <style>
        {
          ".digital-app-cls-2{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:38px}"
        }
      </style>
    </defs>
    <ellipse
      cx={781.43}
      cy={185.92}
      rx={116.57}
      ry={141.92}
      style={{
        fill: "#fec111",
      }}
    />
    <path
      className="digital-app-cls-2"
      d="M848.21 328.19v549.93A74.1 74.1 0 0 1 774.33 952H123.61a74.1 74.1 0 0 1-73.89-73.88V227.4a74.11 74.11 0 0 1 73.89-73.89h533.73"
    />
    <circle className="digital-app-cls-2" cx={806.79} cy={185.92} r={141.92} />
    <path
      style={{
        fill: "#0097a7",
      }}
      d="M669 717.83h-97.21V522.61L363.76 717.83H224.91L571.79 383H669v334.83z"
    />
    <path
      style={{
        fill: "#00bcd4",
      }}
      d="M224 383v334.83h97.21V522.61l208.03 195.22h138.85L321.21 383H224z"
    />
  </svg>
);

export default DigitalApp;
