import React, { useEffect, useState } from "react";
import { render } from "storyblok-rich-text-react-renderer";
import { useRouter } from "next/router";
import { isDev } from "constants/env";
import Storyblok from "utils/storyblok";
import CrafthuntLogo from "../assets/crafthunt_logo.svg";
import DynamicComponent from "./DynamicComponent";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";



const Contact = ({ blok }) => {
  const [settings, setSettings] = useState({
    content: {
      footer_social: [],
      emailBtn: "",
      phoneBtn: "",
    },
  })

  const router = useRouter();
  const lang = router.locale

  useEffect(() => {
    getStory();
  }, [lang]);

  const getStory = async () => {
    const sbParam = {
      version: isDev() ? "draft" : "published",
      language: lang,
    }
    try {
      const { data: settingsData } = await Storyblok.get(
        "cdn/stories/settings",
        sbParam
      )
      setSettings(settingsData.story)
    } catch (error) {
      console.log(error);
    }
  }

  const getIcon = (icon: string) => {
    switch (icon) {
      case "facebook":
      case "facebook-square":
        return <FaFacebook className="h-6 w-6" />;
      case "instagram":
        return <FaInstagram className="h-6 w-6" />;
      case "linkedin":
        return <FaLinkedin className="h-6 w-6" />;
      case "twitter":
        return <FaTwitter className="h-6 w-6" />;
      case "youtube":
        return <FaYoutube className="h-6 w-6" />;
      case "crafthunt":
        return <CrafthuntLogo className="h-5 w-5" />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-white">
      {/* <!-- Header --> */}
      <div className="bg-gray-50">
        <div className="py-24 lg:py-32">
          <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
            <h1
              className="
                text-4xl
                font-extrabold
                tracking-tight
                text-cyan
                sm:text-5xl
                lg:text-6xl
              "
            >
              {blok.title}
            </h1>
            <p className="mt-6 text-xl text-gray-500 max-w-3xl">
              {blok.subtitle}
            </p>
          </div>
        </div>
      </div>
      <section className="relative bg-white" aria-labelledby="contact-heading">
        <div className="absolute w-full h-1/2 bg-gray-50" aria-hidden="true" />
        {/* <!-- Decorative dot pattern --> */}
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <svg
            className="
              absolute
              z-0
              top-0
              right-0
              transform
              -translate-y-16
              translate-x-1/2
              sm:translate-x-1/4
              md:-translate-y-24
              lg:-translate-y-72
            "
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x="0"
                y="0"
                width="100"
                height="100"
                patternUnits="userSpaceOnUse"
              >
                <svg
                  className="text-gray-200"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                >
                  <g fillRule="evenodd">
                    <g fill="currentColor">
                      <path
                        opacity=".5"
                        d={blok.d}
                      />
                      <path d="M6 5V0H5v5H0v1h5v94h1V6h94V5H6z" />
                    </g>
                  </g>
                </svg>
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative bg-white shadow-xl">
            <h2 id="contact-heading" className="sr-only">Contact us</h2>

            <div className="grid grid-cols-1 lg:grid-cols-3">
              {/* <!-- Contact information --> */}
              <div
                className="
                relative
                overflow-hidden
                py-10
                px-6
                bg-gradient-to-b
                from-cyan
                to-cyan-dark
                sm:px-10
                xl:p-12
              "
              >
                {/* <!-- Decorative angle backgrounds --> */}
                <div className="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
                  <svg
                    className="absolute inset-0 w-full h-full"
                    width="343"
                    height="388"
                    viewBox="0 0 343 388"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                      fill="url(#linear1)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient id="linear1" x1="254.553" y1="107.554" x2="961.66" y2="814.66" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#fff" />
                        <stop offset="1" stopColor="#fff" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className="
                    hidden
                    absolute
                    top-0
                    right-0
                    bottom-0
                    w-1/2
                    pointer-events-none
                    sm:block
                    lg:hidden
                  "
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 w-full h-full"
                    width="359"
                    height="339"
                    viewBox="0 0 359 339"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                      fill="url(#linear2)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear2"
                        x1="192.553"
                        y1="28.553"
                        x2="899.66"
                        y2="735.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset="1" stopColor="#fff" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 w-full h-full"
                    width="160"
                    height="678"
                    viewBox="0 0 160 678"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                      fill="url(#linear3)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear3"
                        x1="192.553"
                        y1="325.553"
                        x2="899.66"
                        y2="1032.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset="1" stopColor="#fff" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h3 className="text-lg font-medium text-white">
                  {blok.contactTitle}
                </h3>
                <p className="mt-6 text-base text-white max-w-3xl">
                  {blok.contactSubTitle}
                </p>
                <dl className="mt-8 space-y-6">
                  <dt><span className="sr-only">Phone number</span></dt>
                  <dd className="flex text-base text-white">
                    <PhoneIcon
                      className="flex-shrink-0 w-6 h-6 text-cyan-light"
                      aria-hidden="true"
                    />
                    <a href={`tel:${blok.phoneNumber}`} className="ml-3">{blok.phoneNumber}</a>
                  </dd>
                  <dt><span className="sr-only">Email</span></dt>
                  <dd className="flex text-base text-white">
                    <EnvelopeIcon
                      className="flex-shrink-0 w-6 h-6 text-cyan-light"
                      aria-hidden="true"
                    />
                    <a href={`mailto:${blok.email}`} className="ml-3">{blok.email}</a>
                  </dd>
                </dl>
                <div className="mt-8 flex space-x-6 md:order-2">
                  {settings.content.footer_social.map((item) => (
                    <a
                      key={item.name}
                      href={item.link.url}
                      className="text-cyan-light hover:text-cyan"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="sr-only">{item.name}</span>
                      {item.icon && (
                        <span>{getIcon(item.icon)}</span>
                      )}
                    </a>
                  ))}
                </div>
              </div>
              {/* <!-- Contact form --> */}
              <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                {blok.contactForm.map((item) => (
                  <DynamicComponent key={item._uid} blok={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Contact grid --> */}
      <section aria-labelledby="offices-heading">
        <div className="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h2 id="offices-heading" className="text-3xl font-extrabold text-cyan">
            {blok.header}
          </h2>
          <p className="mt-6 text-lg text-gray-500 max-w-3xl">
            {blok.subHeader}
          </p>
          <div className="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
            {blok.textOptions.map((office) => (
              <div key={office._uid}>
                <h3 className="text-lg font-medium text-cyan-dark">
                  {office.value}
                </h3>
                <div
                  className="mt-2 text-base text-gray-500"
                >
                  {render(office.description)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>





  );
}

export default Contact;

