// These are the only img types that our firebase resize extensions supports
const RESIZABLE_IMGS_REGEX = /\.(gif|jpe?g|tiff?|png|webp)/i;

export enum ImageSizeEnum {
  FULL = "",
  LARGE = "_1280x1280",
  MEDIUM = "_800x800",
  SMALL = "_400x400",
  TINY = "_200x200",
  AVATAR = "_80x80",
}

export const getResizedImageUrl = (
  imageUrl: string,
  imageSize: ImageSizeEnum
): string => {
  if (!imageUrl || !imageUrl.includes("firebasestorage")) {
    return imageUrl;
  }
  const indexOfFileFormat = imageUrl.search(RESIZABLE_IMGS_REGEX);
  if (indexOfFileFormat != -1) {
    return `${imageUrl.slice(0, indexOfFileFormat)}${imageSize}${imageUrl.slice(
      indexOfFileFormat
    )}`;
  }
  return imageUrl;
};

export const transformImage = (
  image: string | undefined,
  option: string,
  firebaseOption = ImageSizeEnum.MEDIUM
) => {
  if (!image) return "";
  if (!option) return "";

  // if SVG, then return image
  if (image.indexOf(".svg") !== -1) return image;

  if (image.indexOf("storyblok.com") !== -1) {
    const imageService = "https://img2.storyblok.com/";

    const nonFilteredPath =
      image.indexOf("https") > -1
        ? "https://a.storyblok.com"
        : "//a.storyblok.com";

    const path = image.replace(nonFilteredPath, "");
    return imageService + option + path;
  }

  if (image.indexOf("firebasestorage") !== -1) {
    return getResizedImageUrl(image, firebaseOption);
  }

  return image;
};
