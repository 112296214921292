import { isDev } from 'constants/env';
import Markdown from 'marked-react';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';
import { formatDate } from 'utils/date.helper';
import Storyblok from 'utils/storyblok';


const PressOverview = ({ blok }) => {
  const router = useRouter();
  const [story, setStory] = useState({ total: 0, data: { stories: [] } });
  const lang = router.locale

  useEffect(() => {
    getStory();
  }, [lang]);

  const getStory = async () => {
    const sbParam = {
      version: isDev() ? "draft" : "published",
      language: lang,
      starts_with: 'press',
    }
    try{
      const pressOverviewData = await Storyblok.get(
        "cdn/stories",
        sbParam
      )
      setStory(pressOverviewData)
    } catch (error) {
      console.log(error);
    }
  }

  const pressArticles = story.data && story.data.stories && story.data.stories.length
    ? story.data.stories.filter((item) => {
      return item.content.component !== "page";
    })
    : [];

  return (
    <>
      <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-16 lg:px-8">
        <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
          <div>
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              {blok && blok.title ? blok.title : ""}
            </h2>
            <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
              <p className="text-xl text-gray-500">
                {blok.subtitle}
              </p>
            </div>
          </div>
          <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            {pressArticles.map((post) => (
              <a key={post.name} href={post.content && post.content.relatedArticle ? post.content.relatedArticle.url : ''}>
                <p className="text-sm text-gray-500">
                  <time dateTime={post.first_published_at}>
                    {formatDate(post.first_published_at, router.locale)}
                  </time>
                </p>
                <div className="mt-2 block">
                  <p className="text-xl font-semibold text-gray-900">
                    {post.name}
                  </p>
                  <div className="mt-3 text-base text-gray-500">
                    <Markdown>{post.content.body}</Markdown>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="text-base font-semibold text-cyan hover:text-cyan-dark">
                    {blok.readMoreText}
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default PressOverview;
