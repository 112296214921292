import { Country } from "react-phone-number-input";
import { LanguageOptionDef } from "types/language.types";
import { mapLocaleToPhoneInputCountry } from "utils/language.helper";

export const LANGUAGES: LanguageOptionDef[] = [
  { name: "Deutsch", value: "de", flagIcon: "germanflag" },
  { name: "English", value: "en", flagIcon: "americanflag" },
  // { name: "Polska", value: "pl", flagIcon: "polishflag" },
  // { name: "Română", value: "ro", flagIcon: "romanianflag" },
  // { name: "Español", value: "es", flagIcon: "spanishflag" },
  // { name: "Français", value: "fr", flagIcon: "frenchflag" },
  // { name: "Italiano", value: "it", flagIcon: "italianflag" },
  // { name: "Ελληνική", value: "el", flagIcon: "greekflag" },
  // { name: "Česky", value: "cs", flagIcon: "czechflag" },
  // { name: "Dansk", value: "da", flagIcon: "danishflag" },
  // { name: "Portuguese (Brazil)", value: "pt-br", flagIcon: "brasilflag" },
];

export const LANGUAGES_PHONE = [...LANGUAGES, { value: "ch" }, { value: "at" }]
  .map((lang) => mapLocaleToPhoneInputCountry(lang.value))
  .filter(Boolean) as Country[];
