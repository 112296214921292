const FamilyBusiness = (props) => (
  <svg
    width="63"
    height="61"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1048 1000"
    {...props}
  >
    <defs>
      <style>
        {
          ".family-cls-4,.family-cls-5{fill:none;stroke:#000;stroke-width:24px}.family-cls-4{stroke-miterlimit:10}.family-cls-5{stroke-linejoin:round}"
        }
      </style>
    </defs>
    <path
      d="m345.79 704.6-213.79.51v-24.92c0-60.36 41.05-109.75 91.23-109.75h95.23c21.19 0 40.76 8.81 56.29 23.54"
      style={{
        fill: "#00bcd4",
      }}
    />
    <path
      d="M704 588c15.21-13.44 27.46-17.56 47.7-17.56h95.66c50.4 0 91.64 49.39 91.64 109.75v24.25l-218.72.56"
      style={{
        fill: "#fec111",
      }}
    />
    <path
      d="M343 706.11v-28.83c0-69.85 49.38-127 109.74-127h114.52c60.36 0 109.74 57.15 109.74 127v28.06Z"
      style={{
        fill: "#0297a7",
      }}
    />
    <rect
      className="family-cls-4"
      x={735.16}
      y={355}
      width={169.39}
      height={177.17}
      rx={75.86}
      ry={75.86}
    />
    <path
      className="family-cls-5"
      d="M697.49 591.85a107.55 107.55 0 0 1 64.41-21.41h113.66c59.89 0 108.89 49.39 108.89 109.75v24.25l-259.88.56"
    />
    <path className="family-cls-5" d="m785.69 570.63 33.04 56.6 32.64-56.6" />
    <rect
      className="family-cls-4"
      x={167.16}
      y={355}
      width={169.39}
      height={177.17}
      rx={75.86}
      ry={75.86}
    />
    <path
      className="family-cls-5"
      d="M340.19 704.6 85 705.11v-24.92c0-60.36 49-109.75 108.89-109.75h113.67A107.7 107.7 0 0 1 374.75 594"
    />
    <path
      className="family-cls-5"
      d="m217.69 570.63 33.04 56.6 32.64-56.6M438 383c77.61 0 105.43-17.25 120-34.52 19.82 16.49 31.45 28.43 31.45 28.43C606 378 621 374 630 364"
    />
    <rect
      className="family-cls-4"
      x={438.03}
      y={301}
      width={196}
      height={205}
      rx={87.78}
      ry={87.78}
    />
    <path
      className="family-cls-5"
      d="M343 706.11v-28.83c0-69.85 56.7-127 126-127h131.49c69.3 0 126 57.15 126 127v28.06Z"
    />
    <path className="family-cls-5" d="m496.5 550.5 38.23 65.5 37.77-65.5" />
  </svg>
);

export default FamilyBusiness;
