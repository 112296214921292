import React from "react";
import DynamicComponent from "./DynamicComponent";
import FeatureTextLeft from "./FeatureTextLeft";
import FeatureTextRight from "./FeatureTextRight";

const Features = ({ blok }) => {

  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="relative">
          <h2
            className="
              text-center text-3xl
              leading-8
              font-extrabold
              tracking-tight
              text-gray-900
              sm:text-4xl
            "
          >
            {blok.title}
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            {blok.subtitle}
          </p>
        </div>
        {blok.features && blok.features.length > 0 && (
          <div>
            {blok.features.map((item) => (
              <DynamicComponent blok={item} key={item._uid} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Features;
