import React from 'react';

const Hook = ({ blok }) => {
  return (
    <div className="py-24 bg-gray-50 overflow-hidden lg:py-19">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="relative">
          <h2 className="max-w-6xl mx-auto text-center text-xl mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            {blok.text}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Hook;