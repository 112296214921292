import React from 'react';
import AppleLogo from '../assets/Apple_logo_white.svg';
import GoogleLogo from '../assets/Google_Play_Arrow_logo.svg';
import { render } from 'storyblok-rich-text-react-renderer';
import { transformImage } from 'utils/image.helper';
import { StarIcon } from '@heroicons/react/20/solid';

const Hero = ({ blok }) => {

  return (
    <div className="relative bg-gray-50">
      <main className="lg:relative">
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl text-center sm:text-left">
              <span className="block xl:inline">{blok.title}</span>
              <span className="block text-cyan xl:inline">
                {blok.titleContrast}
              </span>
            </h1>
            <div
              className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto text-left"
            >
              {render(blok.bullets)}
            </div>
            <div className="mt-6">
              <div className="inline-flex items-center divide-x divide-gray-300">
                <div className="flex-shrink-0 flex pr-5">
                  <StarIcon className="h-5 w-5 text-amber" aria-hidden="true" />
                  <StarIcon className="h-5 w-5 text-amber" aria-hidden="true" />
                  <StarIcon className="h-5 w-5 text-amber" aria-hidden="true" />
                  <StarIcon className="h-5 w-5 text-amber" aria-hidden="true" />
                  <StarIcon className="h-5 w-5 text-amber" aria-hidden="true" />
                </div>
                <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                  <div
                    className="min-w-0 flex-1 pl-2 py-1 text-sm text-gray-500 sm:py-3"
                  >
                    {render(blok.recommendationText)}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 sm:flex sm:justify-center lg:justify-start">
              {blok.iosLink.url.length > 0 && (
                <div className="rounded-md shadow">
                  <a
                    href={blok.iosLink.url}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-700 hover:bg-gray-500 md:py-4 md:text-lg md:px-10"
                  >
                    <AppleLogo
                      className="-ml-1 mr-3 h-5 w-5"
                      alt="App Store"
                    />
                    {blok.iosTitle || 'Apple App Store'}
                  </a>
                </div>
              )}
              {blok.androidLink.url.length > 0 && (
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                  <a
                    href={blok.androidLink.url}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-700 hover:bg-gray-500 md:py-4 md:text-lg md:px-10"
                  >
                    <GoogleLogo
                      className="-ml-1 mr-3 h-5 w-5"
                      alt="Play Store"
                    />
                    {blok.androidTitle || 'Google Play Store'}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          {blok.image?.filename && (
            <img
              className="absolute inset-0 w-full h-full object-cover"
              src={transformImage(blok.image.filename, '1500x0/filters:quality(80)')}
              alt=""
              rel="preload"
            />
          )}
        </div>
      </main>
    </div>
  );
};

export default Hero;
