import React from 'react';

const FeaturesLine = ({ blok }) => {
  return (
    <div className="py-20 bg-white">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">A better way to manage construction.</h2>
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-8">
          {blok.elements.map((feature) => (
            <div key={feature._uid}>
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-cyan text-white">
                  <img src={feature.icon.filename} className="h-6 w-6" alt="" />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                  {feature.value}
                </p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default FeaturesLine;
