import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import DynamicIcon from "components/icons/DynamicIcon";
import { useRouter } from "next/router";
import { TranslatedSlugDef } from "types/translation.type";
import { useEffect } from "react";
import { LanguageOptionDef } from "types/language.types";
import { LANGUAGES } from "constants/languages";
import { convertLocaleForAnalytics } from "utils/language.helper";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import posthog from 'posthog-js'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

type LanguageSelectorProps = {
  translatedSlugs: TranslatedSlugDef[];
};

const LanguageSelector = ({ translatedSlugs }: LanguageSelectorProps) => {
  const router = useRouter();
  const [selected, setSelected] = useState<LanguageOptionDef>(LANGUAGES[0]);

  useEffect(() => {
    if (router.locale) {
      setSelected(
        LANGUAGES.find((language) => language.value === router.locale)
      );
    }
  }, [router.locale]);

  const handleLanguageChange = (lang: string) => {
    window.analytics?.track("language_change", {
      language: convertLocaleForAnalytics(lang),
    });
    posthog.capture("language_change", {
      language: convertLocaleForAnalytics(lang),
    });
    if (selected.value !== lang) {
      if (translatedSlugs?.length) {
        const foundSlug = translatedSlugs.find((x) => x.lang === lang);
        if (foundSlug) {
          router.push(foundSlug.path, foundSlug.path, {
            locale: lang,
          });
        }
      } else {
        const rootUrl = `/${lang !== "de" ? lang : ""}`;
        router.push(rootUrl, rootUrl, {
          locale: lang,
        });
      }

      const newLang = LANGUAGES.find((language) => language.value === lang);

      setSelected(newLang);
    }
  };

  return (
    <div className="flex items-center">
      <Listbox value={router.locale} onChange={handleLanguageChange}>
        {({ open }) => (
          <>
            <div className="relative">
              <Listbox.Button className="relative w-full md:w-40 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm">
                <span className="flex items-center">
                  <div className="flex-shrink-0 text-xl">
                    <DynamicIcon type={selected.flagIcon} />
                  </div>
                  <span className="ml-3 block truncate">{selected.name}</span>
                </span>
                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {LANGUAGES.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-indigo-600" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-3 pr-9"
                        )
                      }
                      value={option.value}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <div className="flex-shrink-0 text-lg">
                              <DynamicIcon type={option.flagIcon} />
                            </div>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {option.name}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default LanguageSelector;
