import NextHead from "next/head";
import { truncate } from "utils/helpers";
import { transformImage } from "utils/image.helper";

export type HeadProps = {
  meta?: {
    title?: string;
    description?: string;
    twitter_title?: string;
    twitter_description?: string;
    og_image?: { filename: string } | string;
    twitter_image?: { filename: string } | string;
  };
};

const Head = ({ meta }: HeadProps) => {
  const title = !!meta?.title ? `${meta.title} | Molteo` : "Molteo";
  const description = meta?.description && truncate(meta.description, 155);
  const twitter_description =
    meta?.twitter_description && truncate(meta.twitter_description, 155);
  return (
    <NextHead>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content="Molteo" />
      <meta name="og:url" content="https://molteo.com/" />
      <meta name="twitter:creator" content="@molteo" />
      <meta name="twitter:site" content="@molteo" />
      <meta name="twitter:card" content="summary" />

      <title>{title}</title>
      <meta property="og:title" content={title} />

      {!!description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </>
      )}
      {!!meta?.og_image && (
        <>
          {/* Facebook */}
          <meta
            property="og:image"
            content={transformImage(
              typeof meta.og_image === "string"
                ? meta.og_image
                : meta.og_image.filename,
              "1200x630/smart/filters:quality(80)"
            )}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          {/* LinkedIn */}
          <meta
            property="og:image"
            content={transformImage(
              typeof meta.og_image === "string"
                ? meta.og_image
                : meta.og_image.filename,
              "1200x627/smart/filters:quality(80)"
            )}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="627" />
        </>
      )}
      {!!meta?.twitter_image && (
        <meta
          property="twitter:image"
          content={transformImage(
            typeof meta.twitter_image === "string"
              ? meta.twitter_image
              : meta.twitter_image.filename,
            "1200x600/filters:quality(80)"
          )}
        />
      )}
      {!!meta?.twitter_title && (
        <meta property="twitter:title" content={meta.twitter_title} />
      )}
      {!!twitter_description && (
        <meta property="twitter:description" content={twitter_description} />
      )}
    </NextHead>
  );
};

export default Head;
