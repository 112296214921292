import Link from "next/link";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import Logo from "../../../assets/molteo.svg";
import TopBanner from "@molteo-ui/TopBanner";
import { TranslatedSlugDef } from "types/translation.type";
import { getLocalizedString } from "utils/helpers";
import { useTranslations } from "context/TranslationContext";
import { ENV } from "constants/env";
import NewsBanner from "components/NewsBanner";
import DynamicComponent from "components/DynamicComponent";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid";

type NavigationProps = {
  locale: string;
  settings: any;
  translatedSlugs?: TranslatedSlugDef[];
};

const Navigation = ({ locale, settings }: NavigationProps) => {
  const { translations } = useTranslations();

  return (
    <div className="sticky top-0 z-50 shadow-md">
      <div className="relative">
        {(locale === "ro" ||
          locale === "pl" ||
          locale === "fr" ||
          locale === "es" ||
          locale === "it" ||
          locale === "cs" ||
          locale === "el") && (
            <TopBanner
              text={getLocalizedString(translations, "language-disclaimer")}
              textMobile={getLocalizedString(
                translations,
                "language-disclaimer-mobile"
              )}
            />
          )}
        {ENV.MAINTENANCE === "true" && (
          <TopBanner
            text={getLocalizedString(translations, "maintenance-banner-title")}
          />
        )}
        <Popover className="relative bg-white z-40 sticky top-0 z-50">
          {({ open }) => (
            <>
              <NewsBanner
                text={settings.newsBannerText}
                mobileText={settings.newsBannerTextMobile}
              />
              <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
                <div>
                  {settings && settings.logoPressUrl && (
                    <Link href={settings.logoPressUrl.url} className="flex">
                      <a>
                        <span className="sr-only">Molteo</span>
                        <Logo className="h-8 w-auto sm:h-6" alt="Molteo" />
                      </a>
                    </Link>
                  )}
                </div>
                <div className="-mr-2 -my-2 md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan">
                    <span className="sr-only">Open menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                  <Popover.Group as="nav" className="flex space-x-10">
                    {settings.main_navi.map((navitem) => (
                      <DynamicComponent key={navitem._uid} blok={navitem} />
                    ))}
                  </Popover.Group>
                  <div className="flex items-center md:ml-12">
                    {settings.right_main_nav.map((navitem) => (
                      <DynamicComponent key={navitem._uid} blok={navitem} />
                    ))}
                  </div>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                    <div className="pt-5 pb-6 px-5">
                      <div className="flex items-center justify-between">
                        <div>
                          <Logo className="h-8 w-auto" alt="Molteo" />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan">
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="mt-6">
                        <nav className="grid gap-6">
                          {/* {solutions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                    >
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-cyan text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <div className="ml-4 text-base font-medium text-gray-900">
                        {item.name}
                      </div>
                    </a>
                  ))} */}
                        </nav>
                      </div>
                    </div>
                    <div className="py-6 px-5">
                      <div className="grid grid-cols-2 gap-4">
                        {settings.main_navi.map((navitem) => (
                          <DynamicComponent key={navitem._uid} blok={navitem} />
                        ))}
                      </div>
                      {settings.right_main_nav && settings.right_main_nav.length >= 2 && (
                        <div className="mt-6">
                          <Link href={settings.right_main_nav[1].destination.url} >
                          <a
                            className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-cyan hover:bg-cyan-dark"
                          >
                            {settings.right_main_nav[1].label}
                          </a>
                          </Link>
                          <p className="mt-6 text-center text-base font-medium text-gray-500">
                            <Link href={settings.right_main_nav[0].destination.url} >
                            <a className="text-cyan hover:text-cyan-dark">
                              {settings.right_main_nav[0].label}
                            </a>
                            </Link>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
};

export default Navigation;
