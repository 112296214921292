import { SVGProps, memo } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 512 336"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none">
      <path
        d="M0 168h512v158.897a8.829 8.829 0 0 1-8.828 8.828H8.828A8.829 8.829 0 0 1 0 326.897V168z"
        fill="#FF4B55"
      />
      <path
        d="M512.001 168H0V9.103A8.829 8.829 0 0 1 8.828.275h494.345a8.829 8.829 0 0 1 8.828 8.828V168z"
        fill="#F5F5F5"
      />
    </g>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
