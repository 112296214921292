const Telescope = (props) => (
  <svg
    width="63"
    height="61"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1048 1000"
    {...props}
  >
    <defs>
      <style>
        {
          ".telescope-cls-3{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:31.01px}"
        }
      </style>
    </defs>
    <path
      transform="rotate(-72.39 251.603 260.124)"
      style={{
        fill: "#0297a7",
      }}
      d="M136.12 228.83h231v62.58h-231z"
    />
    <path
      style={{
        fill: "#f9a01f",
      }}
      d="M784.73 354.86 753.8 452.3 268.31 312.6l39.48-124.35 476.94 166.61z"
    />
    <path
      className="telescope-cls-3"
      d="m783.37 359.15-40.54 127.72-486.78-135.63 51.74-162.99 475.58 170.9z"
    />
    <path
      className="telescope-cls-3"
      transform="rotate(-72.39 235.208 254.915)"
      d="M119.71 206.42h231v97h-231z"
    />
    <path
      className="telescope-cls-3"
      transform="rotate(-72.39 782.768 428.744)"
      d="M745.29 409.24h75v39h-75z"
    />
    <path
      className="telescope-cls-3"
      transform="matrix(.3 -.95 .95 .3 153.3 1092.7)"
      d="M771.79 419.6h103v44h-103z"
    />
    <path
      className="telescope-cls-3"
      transform="rotate(15.99 529.627 449.627)"
      d="M458.5 428.5h142v42h-142z"
    />
    <path
      className="telescope-cls-3"
      d="M509 479 400.5 946.5M539 477l-20.5 469.5M555 481l105.5 469.5"
    />
  </svg>
);

export default Telescope;
