import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';

const VCustomForm = ({ blok }) => {
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [inputFieldValues, setInputFieldValues] = useState([]);
  const [fieldValues, setFieldValues] = useState([]);
  const [checkboxChoices, setCheckboxChoices] = useState([]);
  const [errorIndices, setErrorIndices] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [hubspotCookie, setHubspotCookie] = useState('');

  const isFullWidth = blok.items.length === 1;


  const inputRules = {
    required: (value) => !!value || 'Erforderlicher Angabe!',
    counter: (value) => value.length <= 500 || 'Max 500 Zeichen',
    email: (value) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Ungültige E-Mail-Addresse.';
    },
  };

  const signupForm = {
    context: {},
    fields: [],
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: 'Ich stimme der Speicherung und dem Verarbeiten meiner persönlichen Daten zu.',
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: 'Ich stimme dem erhalten von Marketing E-Mails von der Firma Molteo (Protonaut GmbH) zu.',
          },
        ],
      },
    },
  };

  useEffect(() => {
    initFields();
  }, []);

  const valid = errorIndices.length === 0 && marketingConsent;

  const validationCheck = (index, item, value) => {
    // Check for required fields
    if (item.isRequired && value <= 0) {
      addError(index);
    }
    // Check for the email pattern
    if (item.type === 'email' && value.length > 0) {
      if (!validEmail(value)) {
        addError(index);
      } else {
        removeError(index);
      }
    } else {
      removeError(index);
    }
    console.log(errorIndices);
  };

  const addError = (index) => {
    if (!errorIndices.includes(index)) {
      setErrorIndices([...errorIndices, index]);
    }
  };

  const removeError = (index) => {
    setErrorIndices(errorIndices.filter((errorIndex) => errorIndex !== index));
  };

  const validEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const submit = () => {
    if (valid) {
      // Add the dynamic input field values from the text fields into the required hubspot format
      const updatedFieldValues = fieldValues.map((item, index) => {
        return{
          ...item,
          value: inputFieldValues && inputFieldValues[index] ? inputFieldValues[index] : ''
        }
      });
      // Assign entire array with hubspot specific name and value pair to the form submission body
      setFieldValues(updatedFieldValues)
      signupForm.fields = updatedFieldValues;
      // Parse Website Pagename
      let path = window.location.pathname;
      const page = path.split('/').pop();

      // Get the hubspot Cookie and add to Post Body if it exists
      const cookie = getCookie('hubspotutk');
      // Add the hubspot cookie to the POST body
      if (cookie && cookie.length) {
        signupForm.context = {
          hutk: cookie,
          pageUri: window.location.href,
          pageName: page,
        };
      }

      // Fetch location data and assign to formdata
      getIPData()
        .then((locData) => {
          if (locData && locData.data) {
            if (locData.data.country_name) {
              signupForm.fields.push({
                name: 'country',
                value: locData.data.country_name,
              });
            }
          }
          // Send Segment Event
          if (
            blok.trackingEventId &&
            inputFieldValues &&
            inputFieldValues.length
          ) {
            let currentPath = window.location.pathname;

            let inputValuesObject = {};
            fieldValues.forEach((item, index) => {
              if (inputFieldValues[index]) {
                inputValuesObject[fieldValues[index].name] =
                  inputFieldValues[index];
              }
            });

            global.analytics.track(blok.trackingEventId, {
              ...locData.data,
              ...inputValuesObject,
              currentPath,
            });
          }

          // Segment newsletter event
          if (
            blok.formId === '5590dfa7-a6e0-46f1-8492-a8de0c490ef3' ||
            blok.formId === '94e98b89-92bd-442a-9cf0-989043f92d2c'
          ) {
            let language = 'de';
            if (blok.language === 'en') language = 'en';


            global.analytics.track('newsletter_signup', {
              email: signupForm.fields.find((i) => i.name === 'email').value,
              language: language,
              country: signupForm.fields.find((i) => i.name === 'country')
                .value,
            });
          }

          // Check whether the field has a valid Id
          if (blok.formId) {
            // Post Hubspot form
            axios
              .post(
                `https://api.hsforms.com/submissions/v3/integration/submit/2463807/${blok.formId}`,
                signupForm
              )
              .then((response) => {
                setSuccess(true);
              })
              .catch((error) => {
                setError(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getIPData = async () => {
    const result = await axios.get(
      `https://api.ipgeolocation.io/ipgeo?apiKey=1c07e50faa5041a4adb832af2fde61f7`
    );
    return result;
  };

  const getCookie = (cname) => {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  const updateInputFieldValues = (index, value) => {
    const newValue = [...inputFieldValues]
    newValue[index] = value
    setInputFieldValues(newValue)
  }


  const initFields = () => {
    const initFieldValues = blok.items.map((item, index) => {
      if (item.isRequired) {
        addError(index);
      }

      return {
        name: item.formFieldValue,
        value: '',
      }
    });

    setFieldValues(initFieldValues)
    console.log(errorIndices);
  };

  useEffect(() => {
    initFields();
  }, [blok]);


  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          {/* Header Start */}
          <div>
            {blok.title && (
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {blok.title}
              </h3>
            )}
            {blok.subtitle && (
              <p className="mt-1 text-sm text-gray-500">{blok.subtitle}</p>
            )}
          </div>
          <div className="grid grid-cols-4 gap-4 grid-flow-row auto-rows-max">
            {/* Content Start */}
            {blok.items.map((item, index) => (
              <div
                key={item._uid}
                className={`col-span-4 ${!isFullWidth ? "sm:col-span-2" : ""}`}
              >
                {/* Text Field */}
                {item.type === "text" && (
                  <div>
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {item.label}
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name={fieldValues[index] ? fieldValues[index].name : ""}
                        id="first-name"
                        onChange={(e) => {
                          updateInputFieldValues(index, e.target.value);
                          if (item.isRequired) {
                            validationCheck(index, item, e.target.value);
                          }
                        }}
                        autoComplete="given-name"
                        required={item.isRequired}
                        className="
                          shadow-sm
                          focus:ring-cyan
                          focus:border-cyan
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                )}

                {/* Email Field */}
                {item.type === "email" && (
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {item.label}
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="text"
                        name={fieldValues[index] ? fieldValues[index].name : ""}
                        id="email"
                        onChange={(e) => {
                          updateInputFieldValues(index, e.target.value);
                          if (item.isRequired) {
                            validationCheck(index, item,e.target.value);
                          }
                        }}
                        required={item.isRequired}
                        className={`
                        block
                        w-full
                        pr-10
                        focus:outline-none
                        ${errorIndices.includes(index)
                            ? "focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300"
                            : "focus:ring-cyan focus:border-cyan border-cyan-light text-cyan placeholder-cyan-light"
                          }
                        sm:text-sm
                        rounded-md
                      `}
                        placeholder="you@example.com"
                        aria-invalid="true"
                        aria-describedby="email-error"
                      />
                      {errorIndices.includes(index) && (
                        <div
                          className="
                          absolute
                          inset-y-0
                          right-0
                          pr-3
                          flex
                          items-center
                          pointer-events-none                    "
                        >
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {item.hint && (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="email-error"
                      >
                        {item.hint}
                      </p>
                    )}
                  </div>
                )}

                {/* Dropdown Field */}
                {item.type === "dropdown" && item.options && item.options.length && (
                  <div>
                    <label
                      htmlFor="selection"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {item.label}
                    </label>
                    <div className="mt-1">
                      <select
                        id="selection"
                        name={fieldValues[index] ? fieldValues[index].name : ""}
                        onChange={(e) => {
                          updateInputFieldValues(index, e.target.value);
                          if (item.isRequired) {
                            validationCheck(index, item,e.target.value);
                          }
                        }}
                        autoComplete="selection"
                        required={item.isRequired}
                        className="
                            shadow-sm
                            focus:ring-cyan
                            focus:border-cyan
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                      >
                        {item.options.map((option) => (
                          <option key={option}>{option}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                {/* Text Area Field */}
                {item.type === "textarea" && (
                  <div>
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {item.label}
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        name={fieldValues[index] ? fieldValues[index].name : ""}
                        onChange={(e) => {
                          updateInputFieldValues(index, e.target.value);
                          if (item.isRequired) {
                            validationCheck(index, item,e.target.value);
                          }
                        }}
                        required={item.isRequired}
                        className="
                            shadow-sm
                            focus:ring-cyan
                            focus:border-cyan
                            block
                            w-full
                            sm:text-sm
                            border border-gray-300
                            rounded-md
                          "
                        placeholder="you@example.com"
                      />
                    </div>
                    {item.hint && (
                      <p className="mt-2 text-sm text-gray-500">
                        {item.hint}
                      </p>
                    )}
                  </div>
                )}

                {/* Checkbox Field */}
                {item.type === "checkboxes" &&
                  item.options &&
                  item.options.length && (
                    <div className="pt-8">
                      <div className="relative flex items-center">
                        <div className="flex items-center h-5">
                          <input
                            id="comments"
                            checked={inputFieldValues[index]}
                            onChange={(e) => {
                              updateInputFieldValues(index, e.target.checked);
                              if (item.isRequired) {
                                validationCheck(index, item, e.target.value);
                              }
                            }}
                            aria-describedby="comments-description"
                            name={
                              fieldValues[index] ? fieldValues[index].name : ""
                            }
                            type="checkbox"
                            required={item.isRequired}
                            className="
                                focus:ring-cyan
                                h-4
                                w-4
                                text-indigo-600
                                border-gray-300
                                rounded
                              "
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="comments" className="font-medium text-gray-700">
                            {item.label}
                          </label>
                          {item.hint && (
                            <span
                              id="comments-description"
                              className="text-gray-500"
                            >
                              {item.hint}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            ))}
          </div>
          <div className="mt-6 grid grid-cols-1">
            {/* Marketing Consent, always required */}
            <div>
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="concent"
                    checked={marketingConsent}
                    onChange={(e) => setMarketingConsent(e.target.checked)}
                    aria-describedby="marketing-consent"
                    name="marketingConsense"
                    type="checkbox"
                    className="
                    focus:ring-cyan
                    h-4
                    w-4
                    text-cyan-dark
                    border-cyan
                    rounded
                    "
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="concent" className="font-medium text-gray-700">
                    {blok.marketingConsentCheckText}
                  </label>
                  <p id="marketing-consent" className="text-gray-500">
                    {blok.marketingConsentText}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          {success && (
            <div className="rounded-md p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-green-800">
                    All Good! 🎉
                  </p>
                </div>
              </div>
            </div>
          )}
          {error && (
            <div className="rounded-md p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-red-800">
                    Oh no 🥲
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* Submit Button */}
          {!error && !success && (
            <button
              type="submit"
              disabled={!valid}
              className={`${valid ? "bg-amber" : "bg-gray-400"} border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium ${valid ? "text-grey" : "text-grey-100"} ${valid ? "hover:bg-amber-dark" : "hover:bg-gray-400"} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-dark `}
            >
              {blok.buttonLabel}
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default VCustomForm;


